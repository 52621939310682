import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams} from "react-router-dom";
import Bracket from './Bracket.js';
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';
import * as utils from '../common/Utils.js'

import { collection, query, where, getDoc, getDocs, setDoc, addDoc, updateDoc, doc, Timestamp, collectionGroup } from "firebase/firestore";
import { db } from '../firebase.js';

const SEASON = utils.getCurrentOrMostRecentSeason();
const DATABASE_SOURCE = utils.getDatabaseSource();

const playoffRoundTagNames = ['playin', 'firstround', 'quarterfinals', 'semifinals', 'championship']
const playoffRoundNames = {
  'playin': 'Play-In',
  'firstround': 'First Round',
  'quarterfinals': 'Quarterfinals',
  'semifinals': 'Semifinals',
  'championship': 'Championship',
}

// Used for registering swipe actions:
let startOfSwipeX = 0;
let endOfSwipeX = 0;
let startOfSwipeY = 0;
let endOfSwipeY = 0;

const Seasons = () => {
  const { seasonID } = useParams();

  const [league, setLeague] = useState({
    games: [],
    players: [],
    teams: [],
    seasonTeams: []
  });
  const [dataReady, setDataReady] = useState(false);
  const [seasonData, setSeasonData] = useState({
    leaders: [],
    teams: []
  })

  useEffect(() => {
    getSeasonData();
    getLeague();
  }, [])

  const getSeasonData = async () => {
    const seasonRef = doc(db, 'seasons', seasonID);
    const seasonSnapshot = await getDoc(seasonRef);
    const seasonData = seasonSnapshot.data();
    setSeasonData(seasonData);
  }

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();

    let teams = league.seasonTeams.filter((team) => team.season == seasonID)
    let updatedTeams = []
    for (const t of teams) {
      let teamData = league.teams.find((tm) => tm.id == t.team_id)
      let team = { ...teamData, ...t }

      updatedTeams.push(team)
    }
    league.teams = updatedTeams

    league.games = league.games.filter((game) => game.season == seasonID)

    let sortedGames = league.games.sort(function(a,b){
      return new Date(a.date) - new Date(b.date);
    });

    league.games = sortedGames.map((game) => {
      game.date = game.date.toDate();
      return game;
    });

    setLeague(league);
    setDataReady(true);
  }

  function displayTeam(team, place) {
    return (
        <tr id={team.id + "-team-row"}>
          <td className="league-table-data-name user-name no-wrap"><span>{ place + '. ' }</span><a className="unstyled-link" href={"/teams/" + team.id }>{ team.name.toUpperCase() }</a></td>
          <td className="league-table-data desktop-only"><span>{ team.wins }</span></td>
          <td className="league-table-data desktop-only"><span>{ team.losses }</span></td>
          <td className="league-table-data wide-fixed mobile-only"><span>{ team.wins }-{ team.losses }</span></td>
          <td className="league-table-data wide"><span>{ team.winPercentage }</span></td>
          <td className="league-table-data wide desktop-only"><span>{ team.pointsFor }</span></td>
          <td className="league-table-data wide desktop-only"><span>{ team.pointsAgainst }</span></td>
          <td className="league-table-data wide desktop-only"><span>{ team.pointsFor - team.pointsAgainst }</span></td>
          <td className="league-table-data wide-fixed desktop-only"><span>{ team.streak }</span></td>
        </tr>
    );
  }

  function displayTeams (teams) {
    if (!teams){
      return;
    }

    const team_elements = teams.map((team, place) => displayTeam(team, place+1) )

    return team_elements;
  }

  function abbvWeekday (idx) {
    let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    return weekday[idx];
  }

  function displayPlayoffGame (game) {
    let gameTBD = false;

    let away_team;
    let home_team;
    if (game.away_team_id) {
      away_team = utils.getTeamForID(league.teams, game.away_team_id);
    } else {
      const placeholderName = game.away_team_placeholder
      away_team = {
        seed: '',
        name: placeholderName || 'TBD'
      };
      gameTBD = true;
    }

    if (game.home_team_id) {
      home_team = utils.getTeamForID(league.teams, game.home_team_id);
    } else {
      const placeholderName = game.home_team_placeholder
      home_team = {
        seed: '',
        name: placeholderName || 'TBD'
      };
      gameTBD = true;
    }

    let hours = game.date.getHours();
    let hours_label = 'AM'
    if(hours >= 12){
      hours_label = 'PM';
    }

    if(hours > 12){
      hours -= 12;
    }
    if(hours == 0){
      hours = 12;
    }

    let today = Date.now();

    if (game.status !== 'completed' && game.date > today) {
      return (
        <div className='league-game-container' onClick={() => {
          window.location.href = `/games/${game.id}`
        }} key={game.id + '-game-container'}>
          <div className='league-game-team-names-and-seeds'>
            <div className='league-game-team-seeds'>
              <p className='league-game-team-seed' key={game.id + '-away-seed'}>{ away_team.seed }</p>
              <p className='league-game-team-seed' key={game.id + '-home-seed'}>{ home_team.seed }</p>
            </div>
            <div className='league-game-team-names'>
              <p className={ game.winner === game.away_team_id && game.winner !== '' ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-away-name'}>{ away_team.name.toUpperCase() }</p>
              <p className={ game.winner === game.home_team_id && game.winner !== '' ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-home-name'}>{ home_team.name.toUpperCase() }</p>
            </div>
          </div>
          <div className='league-game-time-and-stats'>
            <p className='league-game-time'>{ abbvWeekday(game.date.getDay()) }, { game.date.getMonth() + 1 }/{ game.date.getDate() }</p>
            <p className='league-game-time'>{ hours }:{ game.date.getMinutes() } { hours_label }</p>
            <p className='league-game-time'>{ utils.getLocationName(game.location, true) }</p>
          </div>
        </div>
      )
    } else if (game.date <= today || game.status === 'completed') {
      return (
        <div className='league-game-container' onClick={() => {
          window.location.href = `/games/${game.id}`
        }} key={game.id + '-game-container'}>
          <div className='league-game-team-names-and-seeds-and-scores'>
            <div className='league-game-team-names-and-seeds'>
              <div className='league-game-team-seeds'>
                <p className='league-game-team-seed' key={game.id + '-away-seed'}>{ away_team.seed }</p>
                <p className='league-game-team-seed' key={game.id + '-home-seed'}>{ home_team.seed }</p>
              </div>
              <div className='league-game-team-names'>
                <p className={ game.winner === game.away_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-away'}>{ away_team.name.toUpperCase() }</p>
                <p className={ game.winner === game.home_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-home'}>{ home_team.name.toUpperCase() }</p>
              </div>
            </div>
            <div className='league-game-team-scores-with-seeds'>
              <p className={ game.winner === game.away_team_id ? 'league-game-team-score game-winner' : 'league-game-team-score' } key={game.id + '-away-score'}>{ game.status === 'completed' ? game.score.away : '' }</p>
              <p className={ game.winner === game.home_team_id ? 'league-game-team-score game-winner' : 'league-game-team-score' } key={game.id + '-home-score'}>{ game.status === 'completed' ? game.score.home : '' }</p>
            </div>
          </div>
          <div className='league-game-time-and-stats'>
            <p className='league-game-time'><b>{ game.status === 'completed' ? 'Final' : 'Pending' }</b></p>
            <p className='league-game-time'>{ game.date.getMonth() + 1 }/{ game.date.getDate() }</p>
          </div>
        </div>
      )
    }
  }

  function displayPlayoffGames (round) {
    // let games = []
    // let rankedTeams = league.teams
    // let totalTeams = rankedTeams.length

    // let roundNameMap = {
    //   '4': 'quarterfinal',
    //   '2': 'semifinal',
    //   '1': 'championship'
    // }
    // let roundName = roundNameMap[numberOfGames.toString()]

    let gamesThisRound = league.games.filter((game) => game.type == 'playoff' && game.playoff_tags && game.playoff_tags.includes(round));

    let sortedGames = gamesThisRound.sort(function(a,b) {
      let orderA = a.playoff_round_order || 0;
      let orderB = b.playoff_round_order || 0;
      if (orderA < orderB) {
        return -1;
      } else if (orderB < orderA) {
        return 1;
      } else {
        return 0;
      }
    })

    // let gamesThisRoundWithTeams = gamesThisRound.filter((game) => game.away_team_id !== '' && game.home_team_id !== '')
    // let gamesThisRoundWithoutTeams = gamesThisRound.filter((game) => game.away_team_id == '' || game.home_team_id == '')
    let gameElements = sortedGames.map((game, place) => displayPlayoffGame(game))
    return (
      <>
        {gameElements}
      </>
    );
  }

  function displayPlayoffsButtons () {
    let playoffButtons = [];

    const playoffRounds = playoffRoundTagNames.length - (league.teams.length / 2) + 1
    const numberPlayoffRounds = playoffRoundTagNames.length - playoffRounds
    for (let i = playoffRounds; i < playoffRoundTagNames.length; i++) {
      let playoffRoundTagName = playoffRoundTagNames[i];
      let playoffRoundName = playoffRoundNames[playoffRoundTagName];

      let buttonClass = '';
      if (SEASON === seasonID) {
        const CURRENT_ROUND = utils.getCurrentPlayoffRound();
        if (playoffRoundTagName == CURRENT_ROUND) {
          buttonClass = 'btn-dark';
        }
      } else {
        if (playoffRoundTagName == 'championship') {
          buttonClass = 'btn-dark';
        }
      }

      let roundClassName = "round_" + (i + 1 - playoffRounds) + "_of_" + numberPlayoffRounds

      playoffButtons.push(
        <>
          <div className='league-bracket-round-button'>
            <a id={ playoffRoundTagName + "Button" } type="button" className={ "btn " + buttonClass } onClick={ () => { clickPlayoffRoundButton(playoffRoundTagName, roundClassName, playoffRounds) } }>{ playoffRoundName }</a>
          </div>
        </>
      )
    }

    return (
      <div className='league-bracket-round-buttons scroll-on-overflow'>
        { playoffButtons }
      </div>
    )
  }

  function clickPlayoffRoundButton(playoffRoundTagName, roundClassName, playoffRounds){
    let leagueBracket = document.getElementsByClassName('league-bracket')[0];
    leagueBracket.classList = '';
    leagueBracket.classList.add('league-bracket');
    leagueBracket.classList.add(roundClassName);

    let button = document.getElementById(playoffRoundTagName + 'Button');
    button.classList.add('btn-dark')

    for (let j = playoffRounds; j < playoffRoundTagNames.length; j++) {
      let playoffOtherRoundTagName = playoffRoundTagNames[j];
      if (playoffRoundTagName == playoffOtherRoundTagName) {
        continue;
      }

      let playoffRoundButton = document.getElementById(playoffOtherRoundTagName + 'Button');
      playoffRoundButton.classList.remove('btn-dark')
    }
  }

  function displayPlayoffs () {
    return (
      <>
        { displayPlayoffRounds() }
      </>
    );
  }

  function swipePlayoffRound (swipeDirection, rounds) {
    let playinButton = document.getElementById('playinButton');
    let firstroundButton = document.getElementById('firstroundButton');
    let quarterfinalsButton = document.getElementById('quarterfinalsButton');
    let semifinalsButton = document.getElementById('semifinalsButton');
    let championshipButton = document.getElementById('championshipButton');

    let roundButtons = [playinButton, firstroundButton, quarterfinalsButton, semifinalsButton, championshipButton]

    for (let i = 0; i < roundButtons.length; i++) {
      let roundButton = roundButtons[i]
      if (roundButton.classList.contains('btn-dark')){
        if (swipeDirection == 'right' && i >= roundButtons.length - 1) {
        } else if (swipeDirection == 'left' && i <= roundButtons.length - rounds) {
        } else if (swipeDirection == 'left') {
          roundButtons[i-1].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
          roundButtons[i-1].click();
        } else if (swipeDirection == 'right') {
          roundButtons[i+1].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
          roundButtons[i+1].click();
        }
        break;
      }
    }
  }

  function checkDirectionOfSwipe (rounds) {
    if (Math.abs(startOfSwipeX - endOfSwipeX) < 10) {
      return;
    }
    if (Math.abs(startOfSwipeX - endOfSwipeX) < Math.abs(startOfSwipeY - endOfSwipeY)) {
      return;
    }

    if (endOfSwipeX < startOfSwipeX) {
      swipePlayoffRound('right', rounds)
    } else if (endOfSwipeX > startOfSwipeX) {
      swipePlayoffRound('left', rounds)
    }
  }

  function displayPlayoffRounds () {
    let divClass = '';
    let gamesToChampionshipMap = {
      'playin': '4',
      'firstround': '3',
      'quarterfinals': '2',
      'semifinals': '1',
      'championship': '0'
    }

    const playoffRounds = playoffRoundTagNames.length - (league.teams.length / 2) + 1
    const numberPlayoffRounds = playoffRoundTagNames.length - playoffRounds

    let currentRound = ''
    if (SEASON === seasonID) {
      currentRound = utils.getCurrentPlayoffRound();
    } else {
      currentRound = 'championship'
    }
    let currentRoundIndex = numberPlayoffRounds - gamesToChampionshipMap[currentRound]

    let roundClassName = "round_" + currentRoundIndex + "_of_" + numberPlayoffRounds

    if (seasonID == 1){
      return (
        <div className={ "league-bracket " + roundClassName } onTouchStart={(e) => {
          startOfSwipeX = e.changedTouches[0].screenX;
          startOfSwipeY = e.changedTouches[0].screenY;
        }} onTouchEnd={(e) => {
          endOfSwipeX = e.changedTouches[0].screenX;
          endOfSwipeY = e.changedTouches[0].screenY;
          let rounds = 3
          checkDirectionOfSwipe(rounds)
        }}>
          { displayPlayoffsForRounds(['quarterfinals', 'semifinals', 'championship']) }
        </div>
      );
    } if (seasonID == 2){
      return (
        <div className={ "league-bracket " + roundClassName } onTouchStart={(e) => {
          startOfSwipeX = e.changedTouches[0].screenX;
          startOfSwipeY = e.changedTouches[0].screenY;
        }} onTouchEnd={(e) => {
          endOfSwipeX = e.changedTouches[0].screenX;
          endOfSwipeY = e.changedTouches[0].screenY;
          let rounds = 5
          checkDirectionOfSwipe(rounds)
        }}>
          { displayPlayoffsForRounds(['playin', 'firstround', 'quarterfinals', 'semifinals', 'championship']) }
        </div>
      );
    }
  }

  function displayPlayoffsForRounds(rounds) {
    let playoffBracket = rounds.map((round) => displayPlayoffRound(round))

    return (
      <>
        { playoffBracket }
      </>
    );
  }

  function displayPlayoffRound(round) {
    let roundNameMap = {
      'playin': 'Play-In',
      'firstround': 'First Round',
      'quarterfinals': 'Quarterfinals',
      'semifinals': 'Semifinals',
      'championship': 'Championship'
    }
    const roundName = roundNameMap[round]

    let roundLocation = 'South Boston Catholic Academy'

    if (seasonID == 1) {
      let roundLocationMap = {
        'quarterfinals': 'South Boston Catholic Academy',
        'semifinals': 'Joseph P. Tynan School',
        'championship': 'South Boston Catholic Academy'
      }

      roundLocation = roundLocationMap[round]
    } else if (seasonID == 2) {
      let roundLocationMap = {
        'playin': 'South Boston Catholic Academy',
        'firstround': 'South Boston Catholic Academy',
        'quarterfinals': 'South Boston Catholic Academy',
        'semifinals': 'South Boston Catholic Academy',
        'championship': 'South Boston Catholic Academy'
      }

      roundLocation = roundLocationMap[round]
    }

    return (
      <div className='league-bracket-round'>
        <h4 className='text-align-center'>{ roundName }</h4>
        <p className='text-align-center'>{ roundLocation }</p>
        <div className='league-bracket-round-games'>
          { displayPlayoffGames(round) }
        </div>
      </div>
    )
  }

  function playoffsStarted () {
    return true;

    let unfinishedRegularSeasonGames = league.games.filter((game) => game.status !== 'completed' && game.type == 'regular_season')

    return unfinishedRegularSeasonGames == 0 && gamesExistForSeason();
  }

  function gamesExistForSeason () {
    return league.games.length > 0
  }

  function showAverageStatRow(leader) {
    let statLabelAbbv = {
      'assists': 'APG',
      'points': 'PPG',
      'rebounds': 'RPG',
      'blocks': 'BPG',
      'steals': 'SPG'
    }

    return (
      <div className='league-game-player-stat-group'>
        <p className='league-game-player-name' key={leader.id + '-player-name'}><a className="unstyled-link" href={"/players/" + leader.id }>{ leader.name }</a> <span className='league-season-average-label'>({ leader.teamName })</span></p>
        <p className='league-game-player-stat' key={leader.id + '-player-stat'}>{ parseFloat(leader.value.toFixed(1)) } <span className='league-season-average-label'>{ statLabelAbbv[leader.stat] }</span></p>
      </div>
    )
  }

  function showTopSeasonStats (stat, season = 0) {
    let statLabel = {
      'assists': 'Assists',
      'points': 'Points',
      'rebounds': 'Rebounds',
      'blocks': 'Blocks',
      'steals': 'Steals'
    }

    const statLeaders = seasonData.leaders.filter((leader) => leader.stat == stat);

    if (statLeaders.length == 0) {
      return;
    } else {
      return (
        <div className='league-player-display'>
          <p className="league-title-standard-small text-center">{ statLabel[stat] }</p>
          <div className='league-player-container'>
            <div className='league-game-player-stats'>
              { statLeaders.map((leader) => showAverageStatRow(leader) ) }
            </div>
          </div>
        </div>
      )
    }
  }


  function showStandings (teams) {
    return (
      <div className="league-table-container scroll-on-overflow">
        <table className="table league-table">
          <thead className="league-table-heading-hole">
            <tr>
              <th className="league-table-heading-name"><span>TEAM</span></th>
              <th className="league-table-heading hole"><span>W</span></th>
              <th className="league-table-heading hole"><span>L</span></th>
              <th className="league-table-heading hole"><span>PCT</span></th>
              <th className="league-table-heading hole"><span>PF</span></th>
              <th className="league-table-heading hole"><span>PA</span></th>
              <th className="league-table-heading hole"><span>+/-</span></th>
              <th className="league-table-heading hole"><span>Strk</span></th>
            </tr>
          </thead>
          <thead className="league-table-heading-total">
            <tr>
              <th className="league-table-heading-name"><span>TEAM</span></th>
              <th className="league-table-heading-small"><span>W - L</span></th>
              <th className="league-table-heading-small"><span>PCT</span></th>
            </tr>
          </thead>
          <tbody>
            { displayTeams(teams) }
          </tbody>
        </table>
      </div>
    )
  }

  function displayOverallStandings () {
    return (
      <div>
        <p className="league-title-standard-secondary text-align-center">Season { seasonID } { gamesExistForSeason() ? '' : ' (No games yet)' }</p>
        { showStandings(seasonData.teams) }
      </div>
    )
  }

  function getPlayoffGames () {
    return league.games.filter((game) => game.type == 'playoff')
  }


  if (dataReady == false) {
    return (
      <div className="Homepage">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Homepage">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">
          { playoffsStarted() ? <Bracket league={ league } isCurrentSeason={ SEASON == seasonID } playoffGames={ getPlayoffGames() }></Bracket> : <></> }

          <div className="league-body">
            { displayOverallStandings() }

            <div className="league-game-stats-container">
              <p className="league-title-skinny-medium"><b>Season Leaders</b></p>
              { showTopSeasonStats('points', seasonID) }
              { showTopSeasonStats('rebounds', seasonID) }
              { showTopSeasonStats('assists', seasonID) }
              { showTopSeasonStats('blocks', seasonID) }
              { showTopSeasonStats('steals', seasonID) }
            </div>
          </div>
        </div>

        <Footer/>
      </div>
    );
  }
}

export default Seasons;
