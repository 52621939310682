import React, { Component, useState, useEffect } from 'react';
import NavBarMini from './NavBarMini.js';
import LoadingAnimation from './LoadingAnimation';
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";

import { collection, query, where, getDocs, setDoc, doc } from "firebase/firestore";
import { db } from '../firebase';
import * as utils from '../common/Utils.js'


const Account = () => {
  const [currentUser, setCurrentUser] = useState({})
  const [dataReady, setDataReady] = useState(false);
  const [currentPlayer, setCurrentPlayer] = useState(null);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setCurrentUser(currentUser)
        getCurrentPlayer(currentUser.email)
      } else {
        setCurrentUser(null)
        window.location.href = "/login";
      }

      setDataReady(true)
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, [])

  const getCurrentPlayer = async (email) => {
    const playerSnapshot = await getDocs(collection(db, 'players'));
    const players = []
    playerSnapshot.forEach((doc) => {
      const player = doc.data()
      players.push(player)
    });

    const player = players.find((p) => p.email == email)

    setCurrentPlayer(player);
  }

  const signOutUser = async () => {
    try {
      const auth = getAuth();
      signOut(auth).then(() => {
        console.log('User signed out successfully');
      })
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (dataReady == false || currentUser == null) {
    return (
      <div className="Account">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  } else {
    return (
      <div className="Account">
        <NavBarMini alwaysShown={true} />

        <div className="league-content">
          <div className='league-body'>
            <div className="account-content">
              <div id="account-header" className="account-section">
                <div className="account-header-intro">
                  { currentPlayer && currentPlayer.firstName ? <h1>Hello { currentPlayer.firstName }!</h1> : <h1>Hello!</h1> }
                  <p>Welcome to the home of basketball in South Boston.</p>
                  { currentPlayer && <a className="btn league-btn-primary" href={ '/players/' + currentPlayer.id }>View my Stats</a> }
                </div>
              </div>
              <div id="account-details" className="account-section">
                <hr></hr>
                <h1>Your profile</h1>

                <div className='account-detail'>
                  { currentPlayer && <><label>Display name:</label> <span>{ currentPlayer.firstName } { currentPlayer.lastName }</span></> }
                </div>
                <div className='account-detail'>
                  <label>Email address:</label> <span>{ currentUser.email }</span>
                </div>
                <div className="account-header-button">
                  <a type="button" className="btn btn-danger" onClick={signOutUser}>Sign Out</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
