import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams } from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';
import * as utils from '../common/Utils.js'
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase.js';

const SEASON = utils.getCurrentOrMostRecentSeason();
const DATABASE_SOURCE = utils.getDatabaseSource();

let DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED = new Date('May 27, 2024');
let SAVE_BLOCKS_AND_STEALS = true;

const AdminPlayers = () => {
  const { playerID } = useParams();
  const [league, setLeague] = useState({
    games: [],
    gameStats: [],
    players: [],
    seasonTeams: [],
    teams: []
  });
  const [dataReady, setDataReady] = useState(false);
  const [currentUser, setCurrentUser] = useState({})

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setCurrentUser(currentUser)

        if (utils.isAdminEmail(currentUser.email)) {
          getLeague()
        } else {
          window.location.href = "/";
        }
      } else {
        setCurrentUser(null)
        window.location.href = "/login";
      }
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, [])

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();

    let sortedGames = league.games.sort(function(a,b){
      return new Date(a.date) - new Date(b.date);
    });
    league.games = sortedGames.map((game) => {
      game.date = game.date.toDate();
      return game;
    });

    let teams = league.seasonTeams.filter((team) => team.season == SEASON)
    let updatedTeams = []
    for (const t of teams) {
      let teamData = league.teams.find((tm) => tm.id == t.team_id)
      let team = { ...teamData, ...t }

      updatedTeams.push(team)
    }
    league.teams = updatedTeams

    setLeague(league);
    setDataReady(true);
  }

  function displayPlayers () {
    let thisSeasonPlayers = []
    for (const team of league.teams) {
      thisSeasonPlayers = thisSeasonPlayers.concat(team.players);
    }

    let playersInCurrentSeason = league.players.filter((player) => thisSeasonPlayers.includes(player.player_id))
    let playersNotInCurrentSeason = league.players.filter((player) => !thisSeasonPlayers.includes(player.player_id))

    playersInCurrentSeason.sort((a, b) => a.first_name.localeCompare(b.first_name));
    playersNotInCurrentSeason.sort((a, b) => a.first_name.localeCompare(b.first_name));

    let displayEditPlayersCurrentSeason = playersInCurrentSeason.map((player) => displayPlayer(player))
    let displayEditPlayersNotInCurrentSeason = playersNotInCurrentSeason.map((player) => displayPlayer(player))

    return (
      <>
        <p>In Season {SEASON}</p>
        { displayEditPlayersCurrentSeason }
        <p>Previous Seasons</p>
        { displayEditPlayersNotInCurrentSeason }
      </>
    )
  }

  function displayPlayer (player) {
    return (
      <div className="league-game" key={player.player_id + '-name'}>
        <div className='league-game-info'>
          <p className="league-title-skinny-small" key={player.player_id + "-name"}>{ utils.displayPlayerName(player) }</p>
        </div>
        { player.image_url ? <img className="league-player-image-mini" src={ player.image_url }></img> : '' }
        <div className='league-game-edit-button'>
          <a type="button" className="btn btn-dark" href={'/admin/players/' + player.player_id}>Edit</a>
        </div>
      </div>
    )
  }

  // function to savePlayerData
  function saveGameData () {


    saveLeague();
  }

  const saveLeague = async () => {
    console.log('saving league')
    await setDoc(doc(db, "league", DATABASE_SOURCE), league);
    console.log('redirect')
    window.location.href = `/players/${playerID}`
  }

  function savePlayer () {
    let plyr = {
      age: document.getElementById('inputPlayerAge').value,
      birthday: {
        day: document.getElementById('inputPlayerBirthdayDay').value,
        month: document.getElementById('inputPlayerBirthdayMonth').value,
        year: document.getElementById('inputPlayerBirthdayYear').value
      },
      email: document.getElementById('inputPlayerEmail').value,
      first_name: document.getElementById('inputPlayerFirstName').value,
      height: {
        feet: document.getElementById('inputPlayerHeightFeet').value,
        inches: document.getElementById('inputPlayerHeightInches').value
      },
      image_url: document.getElementById('inputImageUrl').value,
      jersey_number: document.getElementById('inputPlayerJerseyNumber').value,
      jersey_size: document.getElementById('inputPlayerJerseySize').value,
      last_name: document.getElementById('inputPlayerLastName').value,
      phone: document.getElementById('inputPlayerPhone').value,
      player_id: document.getElementById('inputPlayerID').value
    }

    const playerToUpdate = league.players.find((player) => player.player_id == playerID);

    playerToUpdate.age = plyr.age.toString()
    playerToUpdate.email = plyr.email.toString()
    playerToUpdate.first_name = plyr.first_name.toString()
    playerToUpdate.height.feet = plyr.height.feet.toString()
    playerToUpdate.height.inches = plyr.height.inches.toString()
    playerToUpdate.image_url = plyr.image_url.toString()
    playerToUpdate.jersey_number = plyr.jersey_number.toString()
    playerToUpdate.jersey_size = plyr.jersey_size.toString()
    playerToUpdate.last_name = plyr.last_name.toString()
    playerToUpdate.phone = plyr.phone.toString()

    if (plyr.birthday.year < 2007) {
      playerToUpdate.birthday = {
        day: plyr.birthday.day.toString(),
        month: plyr.birthday.month.toString(),
        year: plyr.birthday.year.toString()
      }
    }

    // Don't allow player ID to ever be updated...
    // playerToUpdate.player_id = plyr.player_id

    console.log(playerToUpdate)

    saveLeague();
  }


  function yearSelectors () {
    let selectors = []
    for (let year = 2010; year > 1950; year--) {
      selectors.push(<option key={year} value={ year.toString() }>{year}</option>)
    }

    return (
      <>
        { selectors }
      </>
    )
  }

  function displayEditPlayer () {
    let player = utils.getPlayerByID(league.players, playerID)

    if (!player.birthday) {
      player.birthday = {}
      player.birthday.month = ''
      player.birthday.day = ''
      player.birthday.year = ''
    }

    return (
      <>
        <form>
          <div className="form-row">
            <div className="form-group">
              <label for="inputPlayerFirstName">First Name</label>
              <input type="text" className="form-control" id="inputPlayerFirstName" defaultValue={ player.first_name } required></input>
            </div>
            <div className="form-group">
              <label for="inputPlayerLastName">Last Name</label>
              <input type="text" className="form-control" id="inputPlayerLastName" defaultValue={ player.last_name } required></input>
            </div>
          </div>
          <div className="form-group">
            <label for="inputPlayerEmail">Email</label>
            <input type="email" className="form-control" id="inputPlayerEmail" defaultValue={ player.email } required></input>
          </div>
          <div className="form-group">
            <label for="inputPlayerPhone">Phone Number</label>
            <input type="tel" className="form-control" id="inputPlayerPhone" defaultValue={ player.phone } required></input>
          </div>
          <div className="form-group">
            <label for="inputImageUrl">Image URL</label>
            { player.image_url ? <img className="league-player-image-mini" src={ player.image_url }></img> : '' }
            <input type="url" className="form-control" id="inputImageUrl" defaultValue={ player.image_url } required></input>
          </div>
          <label for="inputPlayerHeight">Height</label>
          <div className="form-row">
            <div className="form-group">
              <label for="inputPlayerHeightFeet">Feet</label>
              <select className="form-control" id="inputPlayerHeightFeet" defaultValue={ player.height.feet }>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
              </select>
            </div>
            <div className="form-group">
              <label for="inputPlayerHeightInches">Inches</label>
              <select className="form-control" id="inputPlayerHeightInches" defaultValue={ player.height.inches }>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <label for="inputPlayerAge">Age</label>
            <input type="number" className="form-control" id="inputPlayerAge" defaultValue={ player.age } required></input>
          </div>
          <label for="inputBirthday">Birthday</label>
          <div className="form-row">
            <div className="form-group">
              <label for="inputPlayerBirthdayMonth">Month</label>
              <select className="form-control" id="inputPlayerBirthdayMonth" defaultValue={ player.birthday.month }>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
              </select>
            </div>
            <div className="form-group">
              <label for="inputPlayerBirthdayDay">Day</label>
              <select className="form-control" id="inputPlayerBirthdayDay" defaultValue={ player.birthday.day }>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
              </select>
            </div>
            <div className="form-group">
              <label for="inputPlayerBirthdayYear">Year</label>
              <select className="form-control" id="inputPlayerBirthdayYear" defaultValue={ player.birthday.year }>
                { yearSelectors() }
              </select>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label for="inputPlayerJerseyNumber">Jersey Number</label>
              <input type="number" className="form-control" id="inputPlayerJerseyNumber" defaultValue={ player.jersey_number } required></input>
            </div>
            <div className="form-group">
              <label for="inputPlayerJerseySize">Jersey Size</label>
              <select className="form-control" id="inputPlayerJerseySize" defaultValue={ player.jersey_size }>
                <option value="S">S</option>
                <option value="M">M</option>
                <option value="L">L</option>
                <option value="XL">XL</option>
                <option value="XXL">XXL</option>
              </select>
            </div>
          </div>

          <div className="form-group">
            <label for="inputPlayerID">Player ID</label>
            <input type="text" className="form-control" id="inputPlayerID" defaultValue={ player.player_id } required disabled={ playerID ? 'true' : 'false' }></input>
          </div>
          <button className="btn btn-dark" type="button" onClick={savePlayer}>Save Player</button>
        </form>
      </>
    )
  }

  if (dataReady == false || currentUser == null) {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">

          <div className="league-body">
            <p className='league-admin-back-button'><a className='league-link' href="/admin"><b>← Back to Admin Home</b></a></p>
            { playerID ? <p className='league-admin-back-button'><a className='league-link' href="/admin/players"><b>← Back to Admin &gt; Players</b></a></p> : '' }

            <p className="league-title-standard-secondary text-align-center">Edit Players</p>
            <div className="dropdown league-dropdown">
            </div>
            <div className="league-table-container schedule">
              {
                playerID ?
                displayEditPlayer()
                :
                displayPlayers()
              }
            </div>
          </div>

        </div>

        <Footer/>
      </div>
    );
  }
}

export default AdminPlayers;
