import React, { Component, useState, useEffect } from 'react';
import NavBarMini from './NavBarMini.js';
import { getAuth, onAuthStateChanged, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithEmailLink } from "firebase/auth";
import LoadingAnimation from './LoadingAnimation.js';
import { useNavigate, useSearchParams } from "react-router-dom";

import { collection, query, where, getDocs, setDoc, doc } from "firebase/firestore";
import { db } from '../firebase';

const auth = getAuth();



const Login = () => {
  const [view, setView] = useState('login');
  const [dataReady, setDataReady] = useState(false);
  const [players, setPlayers] = useState({});
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const redirectValue = searchParams.get("redirect");

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: `https://www.leaguemorningstar.com/login?redirect=${redirectValue}`,
    // This must be true.
    handleCodeInApp: true
  };

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        if (redirectValue) {
          window.location.href = `/${redirectValue}`;
        } else {
          window.location.href = "/account";
        }
      } else {
        if (isSignInWithEmailLink(auth, window.location.href)) {
          let email = localStorage.getItem('email')

          signInWithEmailLink(auth, email, window.location.href)
            .then(() => {
              localStorage.removeItem('email')
              window.location.href = `/${redirectValue}`;
            }).catch((error) => {
              console.log(error.code)
              console.log(error.message)
            })
        } else {
          getPlayers();
          setDataReady(true)
        }
      }
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, [])

  const getPlayers = async () => {
    const playerSnapshot = await getDocs(collection(db, 'players'));
    const players = []
    playerSnapshot.forEach((doc) => {
      const player = doc.data()
      players.push(player)
    });

    setPlayers(players);
  }

  function togglePasswordVisibility () {
    const passwordInput = document.getElementById('passwordInput');
    const togglePassword = document.getElementById('togglePassword');

    if (passwordInput.type == 'password') {
      passwordInput.type = 'text';
      togglePassword.innerText = 'Hide';
    } else {
      passwordInput.type = 'password';
      togglePassword.innerText = 'Show';
    }
  }

  function login () {
    console.log('login')
    const emailAddressInput = document.getElementById('emailAddressInput');
    const passwordInput = document.getElementById('passwordInput');

    const email = emailAddressInput.value;
    const password = passwordInput.value;

    // console.log('signing in with')
    // console.log('email:', email)
    // console.log('password:', password)

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log('signed in!', user)
        if (redirectValue) {
          window.location.href = `/${redirectValue}`;
        } else {
          window.location.href = "/account";
        }
        })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('error code', error.code)

        // auth/invalid-email
        // auth/configuration-not-found
        // auth/wrong-password

        console.log('error message', error.message)
      });
  }

  function togglePasswordField () {
    const loginEmailButton = document.getElementById('loginEmailButton');
    const loginEmailAndPasswordButton = document.getElementById('loginEmailAndPasswordButton');

    if (loginEmailButton.classList.contains('hidden')) {
      loginEmailButton.classList.remove('hidden')
      loginEmailAndPasswordButton.classList.add('hidden')
    } else {
      loginEmailButton.classList.add('hidden')
      loginEmailAndPasswordButton.classList.remove('hidden')
    }

    // usingPasswordText
    const usingPasswordText = document.getElementById('usingPasswordText');
    if (usingPasswordText.innerText == 'Already have an account?') {
      usingPasswordText.innerText = "Don't have an account?"
    } else {
      usingPasswordText.innerText = 'Already have an account?'
    }

    // passwordField
    const passwordField = document.getElementById('passwordField')
    if (passwordField.classList.contains('hidden')) {
      passwordField.classList.remove('hidden')
    } else {
      passwordField.classList.add('hidden')
    }
  }

  function loginWithoutPassword () {
    setDataReady(false)
    console.log('login w/o password')
    const emailElement = document.getElementById('emailAddressInput');
    const email = emailElement.value;

    const player = players.find((p) => p.email == email)

    if (!player) {
      const emailInputGroup = document.getElementById('emailInputGroup')
      emailInputGroup.classList.add('input-group-error');

      const emailInputFieldError = document.getElementById('emailInputFieldError')
      emailInputFieldError.style.display = 'block';
      emailInputFieldError.innerText = 'Not a valid player email. Use the email you signed up with.'
      setDataReady(true)
      return
    }

    console.log(auth)
    console.log(email)
    console.log(actionCodeSettings)
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('email', email);

        setView('email-confirmation')
        setDataReady(true)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
        console.log('error!')
        console.log(errorCode)
        console.log(errorMessage)
        setDataReady(true)
      });
  }

  if (dataReady == false) {
    return (
      <div className="Homepage">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    if (view == 'login') {
      return (
        <div className="Login">
          <NavBarMini alwaysShown={true} />

          <div className="login-content">

            <div className="login-form">
              <div className="login-header">
                <h2 id="loginHeaderTitle">League Morning Star Login</h2>
                <p className='text-align-center italic'>Use the email you signed up with</p>
              </div>
              <div className="login-body">
                <div id="emailField" className="login-field">
                  <label>Email Address</label>
                  <div id="emailInputGroup" className="login-input-group">
                    <input type="email" id="emailAddressInput"></input>
                  </div>
                  <label id="emailInputFieldError"></label>
                </div>
                <div id="passwordField" className="login-field hidden">
                  <label>Password</label>
                  <div id="passwordInputGroup" className="login-input-group">
                    <input type="password" id="passwordInput"></input>
                    <button id="togglePassword" className="input-btn" type="button" onClick={togglePasswordVisibility}>Show</button>
                  </div>
                  <label id="passwordInputFieldError"></label>
                </div>
                <div id="usingPasswordGroup" className="login-forgot-password">
                  <p id="usingPasswordText" className="login-forgot-password-text" onClick={togglePasswordField}>Already have an account?</p>
                </div>
                <button id="loginEmailButton" className="login-btn" type="submit" onClick={loginWithoutPassword}>Login</button>
                <button id="loginEmailAndPasswordButton" className="login-btn hidden" type="submit" onClick={login}>Login</button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (view == 'email-confirmation') {
      return (
        <div className="Login">
          <NavBarMini alwaysShown={true} />
          <div className="login-content">

            <div className="login-form">
              <div className="login-header">
                <h2 id="loginHeaderTitle"></h2>
              </div>
              <div className="login-body">
                <div className="account-header-intro">
                  <h1>Check your email!</h1>
                  <hr></hr>
                  <p>To confirm your email address, tap the magic link in the email we sent to you.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Login;






// function createAccount () {
//   // don't allow account creation yet...
//   return;

//   console.log('create account')
//   const emailAddressInput = document.getElementById('emailAddressInput');
//   const passwordInput = document.getElementById('passwordInput');

//   const email = emailAddressInput.value;
//   const password = passwordInput.value;

//   console.log('signing in with')
//   console.log('email:', email)
//   console.log('password:', password)

//   createUserWithEmailAndPassword(auth, email, password)
//   .then((userCredential) => {
//     // User created and signed in
//     const user = userCredential.user;
//     console.log('user created!');
//     console.log(user);
//     window.location.href = "/";
//   })
//   .catch((error) => {
//     const errorCode = error.code;
//     const errorMessage = error.message;
//     const emailInputGroup = document.getElementById('emailInputGroup');
//     const passwordInputGroup = document.getElementById('passwordInputGroup');

//     const emailInputFieldError = document.getElementById('emailInputFieldError');
//     const passwordInputFieldError = document.getElementById('passwordInputFieldError');

//     console.log('error')
//     console.log(errorCode)
//     console.log(errorMessage)

//     if (errorCode == 'auth/weak-password') {
//       passwordInputGroup.classList.add('input-group-error');
//       passwordInputFieldError.style.display = 'block';
//       passwordInputFieldError.innerText = 'Password should be at least 6 characters';
//     } else {
//       // email already in use: auth/email-already-in-use
//       emailInputGroup.classList.add('input-group-error');
//       emailInputFieldError.style.display = 'block';
//       emailInputFieldError.innerText = 'Account creation failed';
//     }
//   });
// }
