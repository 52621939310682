import React, { Component, useRef, useState, useEffect } from 'react';
import GameContainer from './GameContainer';
import * as utils from '../common/Utils.js'

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase';
import { getPlayerByID } from '../common/Utils.js';

const SEASON = utils.getCurrentOrMostRecentSeason();
const DATABASE_SOURCE = utils.getDatabaseSource();

const playoffRoundTagNames = ['playin', 'firstround', 'quarterfinals', 'semifinals', 'championship']
const playoffRoundNames = {
  'playin': 'Play-In',
  'firstround': 'First Round',
  'quarterfinals': 'Quarterfinals',
  'semifinals': 'Semifinals',
  'championship': 'Championship',
}

// Used for registering swipe actions:
let startOfSwipeX = 0;
let endOfSwipeX = 0;
let startOfSwipeY = 0;
let endOfSwipeY = 0;

const Bracket = (props) => {
  const league = props.league;
  const playoffGames = props.playoffGames;
  const isCurrentSeason = props.isCurrentSeason;

  function displayPlayoffGame (game) {
    let gameTBD = false;

    let away_team;
    let home_team;
    if (game.away_team_id) {
      away_team = utils.getTeamForID(league.teams, game.away_team_id);
    } else {
      const placeholderName = game.away_team_placeholder
      away_team = {
        seed: '',
        name: placeholderName || 'TBD'
      };
      gameTBD = true;
    }

    if (game.home_team_id) {
      home_team = utils.getTeamForID(league.teams, game.home_team_id);
    } else {
      const placeholderName = game.home_team_placeholder
      home_team = {
        seed: '',
        name: placeholderName || 'TBD'
      };
      gameTBD = true;
    }

    let hours = game.date.getHours();
    let hours_label = 'AM'
    if(hours >= 12){
      hours_label = 'PM';
    }

    if(hours > 12){
      hours -= 12;
    }
    if(hours == 0){
      hours = 12;
    }

    let today = Date.now();


    return (
      <GameContainer league={league} gameID={game.id}></GameContainer>
    )
  }

  function displayPlayoffGames (round, games) {
    // let games = []
    // let rankedTeams = league.teams
    // let totalTeams = rankedTeams.length

    // let roundNameMap = {
    //   '4': 'quarterfinal',
    //   '2': 'semifinal',
    //   '1': 'championship'
    // }
    // let roundName = roundNameMap[numberOfGames.toString()]

    let sortedGames = games.sort(function(a,b) {
      let orderA = a.playoff_round_order || 0;
      let orderB = b.playoff_round_order || 0;
      if (orderA < orderB) {
        return -1;
      } else if (orderB < orderA) {
        return 1;
      } else {
        return 0;
      }
    })

    // let gamesThisRoundWithTeams = gamesThisRound.filter((game) => game.away_team_id !== '' && game.home_team_id !== '')
    // let gamesThisRoundWithoutTeams = gamesThisRound.filter((game) => game.away_team_id == '' || game.home_team_id == '')
    let gameElements = sortedGames.map((game, place) => displayPlayoffGame(game))
    return (
      <>
        { gameElements }
      </>
    );
  }

  function displayPlayoffsButtons () {
    let playoffButtons = [];

    const playoffRounds = playoffRoundTagNames.length - (league.teams.length / 2) + 1
    const numberPlayoffRounds = playoffRoundTagNames.length - playoffRounds
    for (let i = playoffRounds; i < playoffRoundTagNames.length; i++) {
      let playoffRoundTagName = playoffRoundTagNames[i];
      let playoffRoundName = playoffRoundNames[playoffRoundTagName];

      let buttonClass = '';
      if (isCurrentSeason) {
        const CURRENT_ROUND = utils.getCurrentPlayoffRound();
        if (playoffRoundTagName == CURRENT_ROUND) {
          buttonClass = 'btn-dark';
        }
      } else {
        if (playoffRoundTagName == 'championship') {
          buttonClass = 'btn-dark';
        }
      }

      let roundClassName = "round_" + (i + 1 - playoffRounds) + "_of_" + numberPlayoffRounds

      playoffButtons.push(
        <>
          <div className='league-bracket-round-button'>
            <a id={ playoffRoundTagName + "Button" } type="button" className={ "btn " + buttonClass } onClick={ () => { clickPlayoffRoundButton(playoffRoundTagName, roundClassName, playoffRounds) } }>{ playoffRoundName }</a>
          </div>
        </>
      )
    }

    return (
      <div className='league-bracket-round-buttons scroll-on-overflow'>
        { playoffButtons }
      </div>
    )
  }

  function clickPlayoffRoundButton(playoffRoundTagName, roundClassName, playoffRounds){
    let leagueBracket = document.getElementsByClassName('league-bracket')[0];
    leagueBracket.classList = '';
    leagueBracket.classList.add('league-bracket');
    leagueBracket.classList.add(roundClassName);

    let button = document.getElementById(playoffRoundTagName + 'Button');
    button.classList.add('btn-dark')

    for (let j = playoffRounds; j < playoffRoundTagNames.length; j++) {
      let playoffOtherRoundTagName = playoffRoundTagNames[j];
      if (playoffRoundTagName == playoffOtherRoundTagName) {
        continue;
      }

      let playoffRoundButton = document.getElementById(playoffOtherRoundTagName + 'Button');
      playoffRoundButton.classList.remove('btn-dark')
    }
  }

  function displayPlayoffs () {
    return (
      <>
        { displayPlayoffRounds() }
      </>
    );
  }

  function displayPlayoffRounds () {
    let divClass = '';

    const playoffRounds = playoffRoundTagNames.length - (league.teams.length / 2) + 1
    const numberPlayoffRounds = playoffRoundTagNames.length - playoffRounds

    let roundGames = {}
    for (let i = 0; i < playoffGames.length; i++) {
      let game = playoffGames[i]

      // playoff tags can be a string or a list currently.
      // keep this until we support only one
      if (Array.isArray(game.playoff_tags)) {
        for (let j = 0; j < game.playoff_tags.length; j++) {
          let tag = game.playoff_tags[j]

          if (playoffRoundTagNames.includes(tag)) {
            if (tag in roundGames) {
              roundGames[tag].push(game)
            } else {
              roundGames[tag] = [game]
            }
          }
        }
      } else {
        if (playoffRoundTagNames.includes(game.playoff_tags)) {
          if (game.playoff_tags in roundGames) {
            roundGames[game.playoff_tags].push(game)
          } else {
            roundGames[game.playoff_tags] = [game]
          }
        }
      }
    }

    const numberOfRounds = Object.keys(roundGames).length

    let currentRoundFound = false;
    let roundIndex = -1
    let currentRound = 'championship'
    while (!currentRoundFound && roundIndex <= numberOfRounds) {
      let roundTag = playoffRoundTagNames.at(roundIndex)
      let gamesThisRound = roundGames[roundTag]
      let statuses = gamesThisRound.map((game) => game.status)
      let roundComplete = statuses.every(status => status === 'completed');

      if (roundComplete) {
        if (roundIndex != -1) {
          currentRound = playoffRoundTagNames.at(roundIndex + 1)
        }
        currentRoundFound = true
      } else {
        roundIndex -= 1
      }
    }

    let currentRoundIndex = numberPlayoffRounds + roundIndex + 1
    let roundClassName = "round_" + currentRoundIndex + "_of_" + numberPlayoffRounds

    return (
      <div className={ "league-bracket " + roundClassName } onTouchStart={(e) => {
        startOfSwipeX = e.changedTouches[0].screenX;
        startOfSwipeY = e.changedTouches[0].screenY;
      }} onTouchEnd={(e) => {
        endOfSwipeX = e.changedTouches[0].screenX;
        endOfSwipeY = e.changedTouches[0].screenY;
        checkDirectionOfSwipe(numberOfRounds)
      }}>
        { displayPlayoffsForRounds(roundGames) }
      </div>
    );
  }

  function swipePlayoffRound (swipeDirection, rounds) {
    let playinButton = document.getElementById('playinButton');
    let firstroundButton = document.getElementById('firstroundButton');
    let quarterfinalsButton = document.getElementById('quarterfinalsButton');
    let semifinalsButton = document.getElementById('semifinalsButton');
    let championshipButton = document.getElementById('championshipButton');

    let roundButtons = [playinButton, firstroundButton, quarterfinalsButton, semifinalsButton, championshipButton]

    for (let i = 0; i < roundButtons.length; i++) {
      let roundButton = roundButtons[i]
      if (roundButton.classList.contains('btn-dark')){
        if (swipeDirection == 'right' && i >= roundButtons.length - 1) {
        } else if (swipeDirection == 'left' && i <= roundButtons.length - rounds) {
        } else if (swipeDirection == 'left') {
          roundButtons[i-1].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
          roundButtons[i-1].click();
        } else if (swipeDirection == 'right') {
          roundButtons[i+1].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
          roundButtons[i+1].click();
        }
        break;
      }
    }
  }

  function checkDirectionOfSwipe (rounds) {
    if (Math.abs(startOfSwipeX - endOfSwipeX) < 10) {
      return;
    }
    if (Math.abs(startOfSwipeX - endOfSwipeX) < Math.abs(startOfSwipeY - endOfSwipeY)) {
      return;
    }

    if (endOfSwipeX < startOfSwipeX) {
      swipePlayoffRound('right', rounds)
    } else if (endOfSwipeX > startOfSwipeX) {
      swipePlayoffRound('left', rounds)
    }
  }

  function displayPlayoffsForRounds(roundGames) {
    const playoffBracket = Object.entries(roundGames).map(([round, games]) => {
      return displayPlayoffRound(round, games)
    })

    return (
      <>
        { playoffBracket }
      </>
    );
  }

  function displayPlayoffRound(round, games) {
    let roundNameMap = {
      'playin': 'Play-In',
      'firstround': 'First Round',
      'quarterfinals': 'Quarterfinals',
      'semifinals': 'Semifinals',
      'championship': 'Championship'
    }
    const roundName = roundNameMap[round]

    let roundLocation = 'South Boston Catholic Academy'

    return (
      <div className='league-bracket-round'>
        <h4 className='text-align-center'>{ roundName }</h4>
        <p className='text-align-center'>{ roundLocation }</p>
        <div className='league-bracket-round-games'>
          { displayPlayoffGames(round, games) }
        </div>
      </div>
    )
  }

  return (
    <div className="league-body">
      <p className="league-title-standard-secondary text-align-center">Playoffs</p>
        { displayPlayoffsButtons() }
      <div className="league-table-container">
        { displayPlayoffs() }
      </div>
    </div>
  );
}

export default Bracket;
