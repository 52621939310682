import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams } from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';
import * as utils from '../common/Utils.js'
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase.js';

const auth = getAuth();
const user = auth.currentUser

const SEASON = utils.getCurrentOrMostRecentSeason();
const DATABASE_SOURCE = utils.getDatabaseSource();

let DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED = new Date('May 27, 2024');
let SHOW_BLOCKS_AND_STEALS = true;

let defaultPlayer = {
  jersey_number: '',
  player_id: '0'
}

let LEAGUE_RECORD_POINTS;
let LEAGUE_RECORD_REBOUNDS;
let LEAGUE_RECORD_ASSISTS;
let LEAGUE_RECORD_BLOCKS;
let LEAGUE_RECORD_STEALS;
let SEASON_HIGH_POINTS;
let SEASON_HIGH_REBOUNDS;
let SEASON_HIGH_ASSISTS;
let SEASON_HIGH_BLOCKS;
let SEASON_HIGH_STEALS;

const Game = () => {
  const { gameID, id } = useParams();
  const [league, setLeague] = useState({
    games: [],
    gameStats: [],
    players: [],
    teams: []
  });
  const [dataReady, setDataReady] = useState(false);
  const [currentUser, setCurrentUser] = useState({})
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [comments, setComments] = useState({
    posts: [],
    replies: []
  });

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setCurrentUser(currentUser)
        getCurrentPlayer(currentUser.email)
      } else {
        setCurrentUser(null)
      }
      getComments()
      getLeague()
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, [])

  const getComments = async () => {
    // Move to new format after games is converted in DB
    const commentsSnapshot = await getDocs(collection(db, "games", gameID, "comments"));
    const comments = []
    commentsSnapshot.forEach((doc) => {
      const id = doc.id
      const comment = doc.data()
      comments.push(comment)
    });

    const publishedComments = comments.filter((c) => c.status == 'published');

    const posts = publishedComments.filter((c) => c.replyTo == '');
    const replies = publishedComments.filter((c) => c.replyTo !== '');

    const commentsObject = {
      posts: posts,
      replies: replies
    }

    setComments(commentsObject);
  }

  const getCurrentPlayer = async (email) => {
    const playerSnapshot = await getDocs(collection(db, 'players'));
    const players = []
    playerSnapshot.forEach((doc) => {
      const player = doc.data()
      players.push(player)
    });

    const player = players.find((p) => p.email == email)

    setCurrentPlayer(player);
  }

  function getLeagueRecordStats () {
    LEAGUE_RECORD_POINTS = Math.max(...league.gameStats.map(s => s.points || 0))
    LEAGUE_RECORD_REBOUNDS = Math.max(...league.gameStats.map(s => s.rebounds || 0))
    LEAGUE_RECORD_ASSISTS = Math.max(...league.gameStats.map(s => s.assists || 0))
    LEAGUE_RECORD_BLOCKS = Math.max(...league.gameStats.map(s => s.blocks || 0))
    LEAGUE_RECORD_STEALS = Math.max(...league.gameStats.map(s => s.steals || 0))

    let this_season = utils.getGameForID(league.games, gameID).season
    let this_seasons_game_stats = league.gameStats.filter((stats) => utils.getGameForID(league.games, stats.game_id).season == this_season)

    SEASON_HIGH_POINTS = Math.max(...this_seasons_game_stats.map(s => s.points || 0))
    SEASON_HIGH_REBOUNDS = Math.max(...this_seasons_game_stats.map(s => s.rebounds || 0))
    SEASON_HIGH_ASSISTS = Math.max(...this_seasons_game_stats.map(s => s.assists || 0))
    SEASON_HIGH_BLOCKS = Math.max(...this_seasons_game_stats.map(s => s.blocks || 0))
    SEASON_HIGH_STEALS = Math.max(...this_seasons_game_stats.map(s => s.steals || 0))
  }

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();

    let sortedGames = league.games.sort(function(a,b){
      return new Date(a.date) - new Date(b.date);
    });
    league.games = sortedGames.map((game) => {
      game.date = game.date.toDate();
      return game;
    });

    let this_game = utils.getGameForID(league.games, gameID)

    // Only use teams that are in this season
    let teams = league.seasonTeams.filter((team) => team.season == this_game.season)
    let updatedTeams = []
    for (const t of teams) {
      let teamData = league.teams.find((tm) => tm.id == t.team_id)
      let team = { ...teamData, ...t }

      updatedTeams.push(team)
    }
    league.teams = updatedTeams

    setLeague(league);
    setDataReady(true);
  }


  function createNewIDForComment () {
    let dateString = Date.now().toString(36);
    let randomness = Math.random().toString(36).substr(2);
    let commentID = dateString + randomness;

    return commentID;
  }

  function addReply (commentID) {
    const commentFieldElement = document.getElementById(`replyGroup-${commentID}`);
    commentFieldElement.classList.remove('hidden')

    const replyButtonElement = document.getElementById(`replyButtonElement-${commentID}`);
    replyButtonElement.classList.add('hidden')
  }

  function addComment () {
    const commentFieldElement = document.getElementById('commentField');
    commentFieldElement.classList.remove('hidden')

    const cancelButtonElement = document.getElementById('cancelCommentButton');
    cancelButtonElement.classList.remove('hidden')

    const addButtonElement = document.getElementById('addCommentButton');
    addButtonElement.classList.add('hidden')

    const postButtonElement = document.getElementById('postCommentButton');
    postButtonElement.classList.remove('hidden')
  }

  async function createComment(comment) {
    try {
      // Move to new format after games is converted in DB
      const commentRef = doc(db, "comments", comment.id);
      const gameCommentRef = doc(db, "games", gameID, "comments", comment.id);
      await setDoc(commentRef, comment);
      await setDoc(gameCommentRef, comment);
      console.log("Document written with ID: ", comment.id);

      addReplyToComment(comment)
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  function addReplyToComment (comment) {
    let parentCommentDiv;
    let parentComment;

    // Create the comment container
    const commentDiv = document.createElement("div");
    commentDiv.id = `comment-${comment.id}`;

    if (comment.replyTo == '') {
      parentCommentDiv = document.getElementById(`allCommentsGroup`)
      parentComment = document.getElementById(`comment`)
      commentDiv.className = `league-game-comment reply-0`;
    } else {
      parentCommentDiv = document.getElementById(`commentParent-${comment.replyTo}`)
      parentComment = document.getElementById(`comment-${comment.replyTo}`)
      let parentClasses = Array.from(parentComment.classList)
      let replyIndentation = parentClasses.find((c) => c.startsWith('reply-'))
      let replyIndentationValue = replyIndentation.split('-')[1]
      commentDiv.className = `league-game-comment reply-${parseInt(replyIndentationValue) + 1} league-game-comment-reply`;
    }

    // Create the image div
    const imageDiv = document.createElement("div");
    imageDiv.className = "league-commenter-image-mini commenter-image";

    const initials = document.createElement("p");
    initials.className = "commenter-image-initials";
    initials.textContent = `${comment.author.firstName[0]}${comment.author.lastName[0]}`;
    imageDiv.appendChild(initials);

    // Create author and text container
    const authorTextDiv = document.createElement("div");
    authorTextDiv.className = "league-game-comment-author-and-text";

    const authorName = document.createElement("p");
    authorName.className = "league-comment-author-name";
    authorName.innerText = `${comment.author.firstName} ${comment.author.lastName}`;
    authorTextDiv.appendChild(authorName);

    const commentDate = document.createElement("label");
    commentDate.className = "league-comment-date";
    console.log(comment.timestamp)
    commentDate.innerText = getTimeAgo(comment.timestamp);
    authorTextDiv.appendChild(commentDate);

    const commentText = document.createElement("p");
    commentText.className = "league-comment-text";
    commentText.innerText = comment.text;
    authorTextDiv.appendChild(commentText);

    const replyButton = document.createElement("p");
    replyButton.id = `replyButtonElement-${comment.id}`;
    replyButton.className = "league-comment-reply";
    replyButton.innerText = "Reply";
    replyButton.onclick = () => addReply(comment.id);
    authorTextDiv.appendChild(replyButton);

    commentDiv.appendChild(imageDiv);
    commentDiv.appendChild(authorTextDiv);

    // Create the reply group
    const replyGroup = document.createElement("div");
    replyGroup.id = `replyGroup-${comment.id}`;
    replyGroup.className = "hidden";

    const loginBody = document.createElement("div");
    loginBody.className = "login-body";

    const replyField = document.createElement("div");
    replyField.id = `replyField-${comment.id}`;
    replyField.className = "login-field";

    const replyLabel = document.createElement("label");
    replyLabel.textContent = "Add a reply";
    replyField.appendChild(replyLabel);

    const formGroup = document.createElement("div");
    formGroup.className = "form-group";

    const inputGroup = document.createElement("div");
    inputGroup.id = `commentInputGroup-${comment.id}`;
    inputGroup.className = "login-input-group";

    const inputField = document.createElement("input");
    inputField.type = "text";
    inputField.id = `commentInput-${comment.id}`;
    inputGroup.appendChild(inputField);

    formGroup.appendChild(inputGroup);
    replyField.appendChild(formGroup);

    const replyErrorLabel = document.createElement("label");
    replyErrorLabel.id = `replyInputFieldError-${comment.id}`;
    replyField.appendChild(replyErrorLabel);

    loginBody.appendChild(replyField);

    // Add buttons
    const buttonGroup = document.createElement("div");
    buttonGroup.className = "comment-btn-grp";

    const postButton = document.createElement("button");
    postButton.id = `postReplyButton-${comment.id}`;
    postButton.className = "login-btn";
    postButton.type = "submit";
    postButton.textContent = "Post";
    postButton.onclick = () => postReply(comment.id);
    buttonGroup.appendChild(postButton);

    const cancelButton = document.createElement("button");
    cancelButton.id = `cancelReplyButton-${comment.id}`;
    cancelButton.className = "login-btn-secondary";
    cancelButton.type = "submit";
    cancelButton.textContent = "Cancel";
    cancelButton.onclick = () => cancelReply(comment.id);
    buttonGroup.appendChild(cancelButton);

    loginBody.appendChild(buttonGroup);
    replyGroup.appendChild(loginBody);

    const replyParent = document.createElement("div");
    replyParent.id = `commentParent-${comment.id}`;
    replyParent.key = comment.id;

    if (comment.replyTo == '') {
      const hrElement = document.createElement("hr");
      replyParent.appendChild(hrElement)
    }

    replyParent.appendChild(commentDiv)
    replyParent.appendChild(replyGroup)


    if (comment.replyTo == '') {
      parentCommentDiv.insertBefore(replyParent, parentCommentDiv.firstChild);
    } else {
      const allReplies = document.getElementById(`allReplies-${comment.replyTo}`)

      allReplies.insertBefore(replyParent, allReplies.firstChild)
    }
  }

  function showSpinner(spinnerID) {
    console.log('showing spinner for ', spinnerID)

    const replySpinner = document.getElementById(spinnerID)
    replySpinner.classList.remove('hidden')
  }
  function hideSpinner(spinnerID) {
    console.log('hiding spinner for ', spinnerID)
    const replySpinner = document.getElementById(spinnerID)
    replySpinner.classList.add('hidden')
  }

  function postReply (commentID) {
    const spinnerID = `replySpinner-${commentID}`
    showSpinner(spinnerID)

    const commentFieldElement = document.getElementById(`replyGroup-${commentID}`);
    commentFieldElement.classList.add('hidden')

    const commentInput = document.getElementById(`commentInput-${commentID}`);

    if (commentInput.value == '') {
      return;
    }

    const newCommentID = createNewIDForComment()
    const datetime = Date.now()
    const dateObject = new Date(datetime)
    const timestamp = Timestamp.fromDate(dateObject)
    const comment = {
      status: 'published',
      timestamp: timestamp,
      text: commentInput.value,
      author: {
        firstName: currentPlayer.firstName,
        lastName: currentPlayer.lastName
      },
      replyTo: commentID,
      id: newCommentID,
      gameID: gameID,
      playerID: currentPlayer.id,
      likes: []
    }

    commentInput.value = ''

    createComment(comment)
    .then(() => {
      hideSpinner(spinnerID)

      const replyButtonElement = document.getElementById(`replyButtonElement-${commentID}`);
      replyButtonElement.classList.remove('hidden')
    }).catch((error) => {
      console.log(error.code)
      console.log(error.message)
    })
  }

  function postComment () {
    const spinnerID = 'commentSpinner'
    showSpinner(spinnerID)

    const commentFieldElement = document.getElementById('commentField');
    commentFieldElement.classList.add('hidden')

    const cancelButtonElement = document.getElementById('cancelCommentButton');
    cancelButtonElement.classList.add('hidden')

    const postButtonElement = document.getElementById('postCommentButton');
    postButtonElement.classList.add('hidden')

    const commentInput = document.getElementById('commentInput');

    if (commentInput.value == '') {
      return;
    }

    const newCommentID = createNewIDForComment()
    const datetime = Date.now()
    const dateObject = new Date(datetime)
    const timestamp = Timestamp.fromDate(dateObject)
    const comment = {
      status: 'published',
      timestamp: timestamp,
      text: commentInput.value,
      author: {
        firstName: currentPlayer.firstName,
        lastName: currentPlayer.lastName
      },
      replyTo: '',
      id: newCommentID,
      gameID: gameID,
      playerID: currentPlayer.id,
      likes: []
    }

    commentInput.value = ''

    createComment(comment)
    .then(() => {
      hideSpinner(spinnerID)

      const noCommentsText = document.getElementById('noCommentsText')
      noCommentsText.classList.add('hidden')

      const addButtonElement = document.getElementById('addCommentButton');
      addButtonElement.classList.remove('hidden')
    }).catch((error) => {
      console.log(error.code)
      console.log(error.message)

      hideSpinner(spinnerID)

      const addButtonElement = document.getElementById('addCommentButton');
      addButtonElement.classList.remove('hidden')
    })
  }

  function cancelReply (commentID) {
    const commentFieldElement = document.getElementById(`replyGroup-${commentID}`);
    commentFieldElement.classList.add('hidden')

    const replyButtonElement = document.getElementById(`replyButtonElement-${commentID}`);
    replyButtonElement.classList.remove('hidden')

    const commentInput = document.getElementById(`commentInput-${commentID}`);
    commentInput.value = ''
  }

  function cancelComment () {
    const commentFieldElement = document.getElementById('commentField');
    commentFieldElement.classList.add('hidden')

    const cancelButtonElement = document.getElementById('cancelCommentButton');
    cancelButtonElement.classList.add('hidden')

    const addButtonElement = document.getElementById('addCommentButton');
    addButtonElement.classList.remove('hidden')

    const postButtonElement = document.getElementById('postCommentButton');
    postButtonElement.classList.add('hidden')

    const commentInput = document.getElementById('commentInput');
    commentInput.value = ''
  }

  function getIDForTeamName(name) {
    return name.replace(/\s+/g, '-').toLowerCase();
  }

  function getCompletedGames (season) {
    if (season == 0) {
      return league.games.filter((game) => game.status == 'completed')
    } else {
      return league.games.filter((game) => game.status == 'completed' && game.season == season)
    }
  }

  function displayFinishedGameThisWeek (game) {
    // let gameID = `${game.away_team_id}-vs-${game.home_team_id}-${game.date.getMonth() + 1}-${game.date.getDate()}-${game.date.getFullYear()}`

    let away_team = getTeamForID(game.away_team_id);
    let home_team = getTeamForID(game.home_team_id);

    if (id) {
      let opponent_id = game.away_team_id == id ? game.home_team_id : game.away_team_id
      let opponent = getTeamForID(opponent_id);

      if (game.status === 'completed') {
        let opponent_score = game.team1 == id ? game.score.home : game.score.away
        let home = getTeamForID(id);
        let home_score = game.team1 == id ? game.score.away : game.score.home
        let result = id == game.winner ? 'W' : 'L'
        return (
          <div className="league-game">
            <div className='league-game-info'>
            <p className="league-title-standard-small" key={game.id + "-date"}>{ game.date.toDateString() }</p>
            <p key={game.id}>{result} - {home_score}-{opponent_score} vs. { opponent.name.toUpperCase() }</p>
            </div>
            <div className='league-game-edit-button'>
              <a type="button" className="btn btn-dark" href={'/admin/games/' + game.id}>Edit</a>
            </div>
          </div>
        )
      } else {
        return (
          <div className="league-game">
            <div className='league-game-info'>
              <p className="league-title-standard-small" key={game.id + "-date"}>{ game.date.toDateString() }</p>
              <p key={game.id}>Score Pending vs. { opponent.name.toUpperCase() }</p>
            </div>
            <div className='league-game-edit-button'>
              <a type="button" className="btn btn-dark" href={'/admin/games/' + game.id}>Edit</a>
            </div>
          </div>
        )
      }

    } else {
      if (game.status === 'completed') {
        return (
          <div className="league-game">
            <div className='league-game-info'>
              <p className="league-title-standard-small" key={game.id + "-date"}>{ game.date.toDateString() }</p>
              { game.away_team_id === game.winner && <p key={game.id}><b>{ away_team.name.toUpperCase() } { game.score.away }</b> - { game.score.home } { home_team.name.toUpperCase() }</p> }
              { game.home_team_id === game.winner && <p key={game.id}>{ away_team.name.toUpperCase() } { game.score.away } - <b>{ game.score.home } { home_team.name.toUpperCase() }</b></p> }
            </div>
            <div className='league-game-edit-button'>
              <a type="button" className="btn btn-dark" href={'/admin/games/' + game.id}>Edit</a>
            </div>
          </div>
        )
      } else {
        return (
          <div className="league-game">
            <div className='league-game-info'>
              <p className="league-title-standard-small" key={game.id + "-date"}>{ game.date.toDateString() }</p>
              <p key={game.id}>Score Pending - { away_team.name.toUpperCase() } vs. { home_team.name.toUpperCase() }</p>
            </div>
            <div className='league-game-edit-button'>
              <a type="button" className="btn btn-dark" href={'/admin/games/' + game.id}>Edit</a>
            </div>
          </div>
        )
      }

    }
  }

  function displayGameThisWeek (game) {
    // let gameID = `${game.away_team_id}-vs-${game.home_team_id}-${game.date.getMonth() + 1}-${game.date.getDate()}-${game.date.getFullYear()}`

    let away_team = getTeamForID(game.away_team_id);
    let home_team = getTeamForID(game.home_team_id);


    let hours = game.date.getHours();
    let hours_label = 'AM'
    if(hours >= 12){
      hours_label = 'PM';
    }

    if(hours > 12){
      hours -= 12;
    }
    if(hours == 0){
      hours = 12;
    }

    return (
      <div className="league-game" key={game.id + '-game'}>
        <div className='league-game-info'>
          <p className="league-title-skinny-small" key={game.id + "-date"}>{ game.date.toDateString() }</p>
          <p key={game.id}>{ away_team.name.toUpperCase() } vs. { home_team.name.toUpperCase() }</p>
        </div>
        <div className='league-game-edit-button'>
          <a type="button" className="btn btn-dark" href={'/admin/games/' + game.id}>Edit</a>
        </div>
      </div>
    )
  }

  function displayGames () {
    let today = Date.now();
    let oneWeek = today + (7 * 24 * 60 * 60 * 1000);
    let gamesThisWeek;
    let finishedGames;
    if (id) {
      gamesThisWeek = league.games.filter((game) => (game.status !== 'completed' && game.date > today) && (game.away_team_id === id || game.home_team === id))
      finishedGames = league.games.filter((game) => (game.status === 'completed' || game.date <= today) && (game.away_team_id === id || game.home_team === id))
    } else {
      gamesThisWeek = league.games.filter((game) => game.status !== 'completed' && game.date > today)
      finishedGames = league.games.filter((game) => game.date <= today || game.status === 'completed' )
    }
    if (gamesThisWeek.length == 0 && finishedGames.length < 40) {
      return <p>No upcoming games this week</p>
    }
    let sortedGamesThisWeek = gamesThisWeek.sort((a, b) => (a.date < b.date) ? -1 : 1)
    let sortedFinishedGames = finishedGames.sort((a, b) => (a.date < b.date) ? -1 : 1)

    let gameElements = sortedGamesThisWeek.map((game, place) => displayGameThisWeek(game) )
    let gameOverElements = sortedFinishedGames.map((game, place) => displayFinishedGameThisWeek(game) )
    return (
      <>
        {gameOverElements}
        {gameElements}
      </>
    );
  }

  function getOldGameFromGameID () {
    let game = league.games.filter((game) => gameID === gameIDForGame(game));
    return game[0];
  }
  function gameIDForGame (game) {
    let game_id = `${game.away_team_id}-vs-${game.home_team}-${game.date.getMonth() + 1}-${game.date.getDate()}-${game.date.getFullYear()}`;
    return game_id;
  }

  function getGameFromGameID () {
    return league.games.find((game) => gameID === game.id);
  }

  function displayPlayerInputRowsForTeam(stat) {
    let player = league.players.find((player) => player.player_id == stat.player_id)

    let maxPlayerPoints;
    let maxPlayerRebounds;
    let maxPlayerAssists;
    let maxPlayerBlocks;
    let maxPlayerSteals;

    let decoratorClasses = {
      'points': [],
      'rebounds': [],
      'assists': [],
      'blocks': [],
      'steals': []
    };

    let playerTeam = utils.getCurrentTeamForPlayerID(league.teams, stat.player_id)
    if (!playerTeam) {
      playerTeam = {
        'id': stat.team_id,
        'name': 'No Name'
      }
    }

    if (!player) {
      let player_id = stat.player_id
      player = defaultPlayer;
      player['player_id'] = player_id
      player['first_name'] = player_id
      player['last_name'] = ''
    } else {
      let allSinglePlayerStats = league.gameStats.filter((s) => s.player_id == player.player_id)
      maxPlayerPoints = Math.max(...allSinglePlayerStats.map(s => s.points || 0))
      maxPlayerRebounds = Math.max(...allSinglePlayerStats.map(s => s.rebounds || 0))
      maxPlayerAssists = Math.max(...allSinglePlayerStats.map(s => s.assists || 0))
      maxPlayerBlocks = Math.max(...allSinglePlayerStats.map(s => s.blocks || 0))
      maxPlayerSteals = Math.max(...allSinglePlayerStats.map(s => s.steals || 0))

      if (stat.points) {
        if (stat.points >= maxPlayerPoints) {
          if (stat.points >= LEAGUE_RECORD_POINTS) {
            decoratorClasses.points.push('league-high-stat')
          } else if (stat.points >= SEASON_HIGH_POINTS) {
            decoratorClasses.points.push('season-high-stat')
          } else {
            decoratorClasses.points.push('career-high-stat')
          }
        }
      }
      if (stat.rebounds) {
        if (stat.rebounds >= maxPlayerRebounds) {
          if (stat.rebounds >= LEAGUE_RECORD_REBOUNDS) {
            decoratorClasses.rebounds.push('league-high-stat')
          } else if (stat.rebounds >= SEASON_HIGH_REBOUNDS) {
            decoratorClasses.rebounds.push('season-high-stat')
          } else {
            decoratorClasses.rebounds.push('career-high-stat')
          }
        }
      }
      if (stat.assists) {
        if (stat.assists >= maxPlayerAssists) {
          if (stat.assists >= LEAGUE_RECORD_ASSISTS) {
            decoratorClasses.assists.push('league-high-stat')
          } else if (stat.assists >= SEASON_HIGH_ASSISTS) {
            decoratorClasses.assists.push('season-high-stat')
          } else {
            decoratorClasses.assists.push('career-high-stat')
          }
        }
      }
      if (stat.blocks) {
        if (stat.blocks >= maxPlayerBlocks) {
          if (stat.blocks >= LEAGUE_RECORD_BLOCKS) {
            decoratorClasses.blocks.push('league-high-stat')
          } else if (stat.blocks >= SEASON_HIGH_BLOCKS) {
            decoratorClasses.blocks.push('season-high-stat')
          } else {
            decoratorClasses.blocks.push('career-high-stat')
          }
        }
      }
      if (stat.steals) {
        if (stat.steals >= maxPlayerSteals) {
          if (stat.steals >= LEAGUE_RECORD_STEALS) {
            decoratorClasses.steals.push('league-high-stat')
          } else if (stat.steals >= SEASON_HIGH_STEALS) {
            decoratorClasses.steals.push('season-high-stat')
          } else {
            decoratorClasses.steals.push('career-high-stat')
          }
        }
      }
    }

    if (stat.status == 'dnp') {
      return (
        <>
          <tr>
            <th className="league-table-data-name no-wrap" scope="row"><a className="unstyled-link" href={"/players/" + player.player_id }>{ player.jersey_number != '' ? '#' : '' }{ player.jersey_number } { displayPlayerName(player) }</a></th>
            <td className="league-table-data-name stat-line dnp" colSpan="5">
              <p id={ getIDForPlayerAndStat(player, 'dnp') }>DNP</p>
            </td>
          </tr>
        </>
      )
    } else if (stat.status == 'played') {
      return (
        <>
          <tr>
            <th className="league-table-data-name no-wrap" scope="row"><a className="unstyled-link" href={"/players/" + player.player_id }>{ player.jersey_number != '' ? '#' : '' }{ player.jersey_number } { displayPlayerName(player) }</a>{ playerTeam.id != stat.team_id && getGameForID(stat.game_id).season == SEASON ? '*' : ''}</th>
            <td className={ "league-table-data-name stat-line " + decoratorClasses.points.join('')}>
              <p id={ getIDForPlayerAndStat(player, 'points') }>{ stat.points }</p>
            </td>
            <td className={ "league-table-data-name stat-line " + decoratorClasses.rebounds.join('')}>
              <p id={ getIDForPlayerAndStat(player, 'rebounds') }>{ stat.rebounds }</p>
            </td>
            <td className={ "league-table-data-name stat-line " + decoratorClasses.assists.join('')}>
              <p id={ getIDForPlayerAndStat(player, 'assists') }>{ stat.assists }</p>
            </td>
            { SHOW_BLOCKS_AND_STEALS ? <>
              <td className={ "league-table-data-name stat-line " + decoratorClasses.blocks.join('')}>
                <p id={ getIDForPlayerAndStat(player, 'blocks') }>{ stat.blocks }</p>
              </td>
              <td className={ "league-table-data-name stat-line " + decoratorClasses.steals.join('')}>
                <p id={ getIDForPlayerAndStat(player, 'steals') }>{ stat.steals }</p>
              </td>
            </> : '' }
          </tr>
        </>
      )
    }
  }

  function displayPlayerInputRows(game, away_team, home_team) {
    getLeagueRecordStats();

    let awayTeamPlayerStats = league.gameStats.filter((stat) => stat.game_id == game.id && stat.team_id == away_team.id)
    let awayTeamPlayerStatsSorted = awayTeamPlayerStats.sort((a, b) => {
      let playerA = league.players.find((player) => player.player_id == a.player_id)
      let playerB = league.players.find((player) => player.player_id == b.player_id)

      return jerseyNumberSort(playerA, playerB)
    })
    let awayTeamPlayerRows = awayTeamPlayerStatsSorted.map((stat, place) => displayPlayerInputRowsForTeam(stat))

    let homeTeamPlayerStats = league.gameStats.filter((stat) => stat.game_id == game.id && stat.team_id == home_team.id)
    let homeTeamPlayerStatsSorted = homeTeamPlayerStats.sort((a, b) => {
      let playerA = league.players.find((player) => player.player_id == a.player_id)
      let playerB = league.players.find((player) => player.player_id == b.player_id)

      return jerseyNumberSort(playerA, playerB)
    })
    let homeTeamPlayerRows = homeTeamPlayerStatsSorted.map((stat, place) => displayPlayerInputRowsForTeam(stat))

    return (
      <>
        <tr>
          <th className="league-table-heading-name" colSpan="6"><span>{ away_team.name.toUpperCase() }</span></th>
        </tr>
        { awayTeamPlayerRows }
        <tr>
          <th className="league-table-heading-name" colSpan="6"><span>{ home_team.name.toUpperCase() }</span></th>
        </tr>
        { homeTeamPlayerRows }
      </>
    )
  }

  function jerseyNumberSort (a, b) {
    if (!a && !b) {
      return 0;
    } else if (!a) {
      return 1;
    } else if (!b) {
      return -1;
    } else if (a.jersey_number == '' && b.jersey_number == '') {
      return 0;
    } else if (a.jersey_number == '') {
      return 1;
    } else if (b.jersey_number == '') {
      return -1;
    } else {
      let number1 = parseInt(a.jersey_number)
      let number2 = parseInt(b.jersey_number)

      return number1 < number2 ? -1 : 1
    }
  }

  function getIDForPlayerAndStat(player, stat) {
    return `${player.player_id}-${stat}`
  }

  function displayGame () {
    let game = getGameFromGameID();

    if (game.date < DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED) {
      SHOW_BLOCKS_AND_STEALS = false;
    }

    if (game.winner) {
      return displayPastGame();
    } else {
      return displayFutureGame();
    }

  }

  function abbvWeekday (idx) {
    let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    return weekday[idx];
  }
  function getRecordForID (given_id = null) {
    let team_id = given_id === null ? id : given_id
    if (team_id) {
      let allGames = league.games.filter((game) => game.status === 'completed' && game.season == SEASON && (game.away_team_id === team_id || game.home_team_id === team_id))
      let allGamesWon = league.games.filter((game) => game.status === 'completed' && game.season == SEASON && (game.winner === team_id))
      let gamesPlayed = allGames.length;
      let gamesWon = allGamesWon.length;
      return `${gamesWon}-${gamesPlayed - gamesWon}`
    } else {
      return '';
    }
  }

  function displayFutureGame () {
    let game = getGameFromGameID();
    let gameTBD = false;

    let away_team;
    let home_team;
    if (game.away_team_id) {
      away_team = utils.getTeamForID(league.teams, game.away_team_id);
    } else {
      const placeholderName = game.away_team_placeholder
      away_team = {
        seed: '',
        name: placeholderName || 'TBD',
        players: []
      };
      gameTBD = true;
    }

    if (game.home_team_id) {
      home_team = utils.getTeamForID(league.teams, game.home_team_id);
    } else {
      const placeholderName = game.home_team_placeholder
      home_team = {
        seed: '',
        name: placeholderName || 'TBD',
        players: []
      };
      gameTBD = true;
    }

    let hours = game.date.getHours();
    let hours_label = 'AM'
    if(hours >= 12){
      hours_label = 'PM';
    }

    if(hours > 12){
      hours -= 12;
    }
    if(hours == 0){
      hours = 12;
    }

    return (
      <>
        <div className='league-game-display'>
          <div className='text-left'>
            { gameTBD ?
              <p className="league-title-standard-small"><b>{ away_team.name.toUpperCase() }</b></p>
              :
              <p className="league-title-standard-small"><a className="unstyled-link" href={ "/teams/" + away_team.id }><b>{ away_team.name.toUpperCase() }</b></a></p>
            }
          </div>

          <div className='text-right'>
            { gameTBD ?
            <p className="league-title-standard-small"><b>{ home_team.name.toUpperCase() }</b></p>
            :
              <p className="league-title-standard-small"><a className="unstyled-link" href={"/teams/" + home_team.id }><b>{ home_team.name.toUpperCase() }</b></a></p>
            }
          </div>
        </div>

        <div className='league-game-display'>
          <div className='text-left'>
            <p className='league-title-standard league-team-record' key={gameID + '-away-record'}>{ getRecordForID(game.away_team_id) }</p>
          </div>

          <div className='text-center margin-top-20'>
            {
              ['postponed'].includes(game.status) ?
              <>
                <p className='league-title-standard-small'>Postponed</p>
                <p className='league-title-standard-small'>{ abbvWeekday(game.date.getDay()) }, { game.date.getMonth() + 1 }/{ game.date.getDate() }</p>
              </>
              :
              <>
                <p className='league-title-standard-small text-center'>{ abbvWeekday(game.date.getDay()) }, { game.date.getMonth() + 1 }/{ game.date.getDate() }</p>
                <p className='league-game-location text-center'>{ hours }:{ game.date.getMinutes() } { hours_label }</p>
                <p className='league-game-location text-center'>{ utils.getLocationName(game.location) }</p>
              </>
            }
          </div>

          <div className='text-right'>
            <p className='league-title-standard league-team-record' key={gameID + '-home-record'}>{ getRecordForID(game.home_team_id) }</p>
          </div>
        </div>

        { gameTBD ? '' : <>
          <div className="league-game-stats-container">
            <p className="league-title-skinny-medium"><b>Season Leaders</b></p>
            { showTopSeasonStats(game, away_team, home_team, 'points', SEASON) }
            { showTopSeasonStats(game, away_team, home_team, 'rebounds', SEASON) }
            { showTopSeasonStats(game, away_team, home_team, 'assists', SEASON) }
            { showTopSeasonStats(game, away_team, home_team, 'blocks', SEASON) }
            { showTopSeasonStats(game, away_team, home_team, 'steals', SEASON) }
          </div>

          <div className="league-game-stats-container">
            <p className="league-title-skinny-medium"><b>All-Time Leaders</b></p>
            { showTopSeasonStats(game, away_team, home_team, 'points') }
            { showTopSeasonStats(game, away_team, home_team, 'rebounds') }
            { showTopSeasonStats(game, away_team, home_team, 'assists') }
            { showTopSeasonStats(game, away_team, home_team, 'blocks') }
            { showTopSeasonStats(game, away_team, home_team, 'steals') }
          </div>
        </> }
      </>
    )
  }

  function statsExistForGame (game) {
    let playerNames = Object.keys(game.player_stats);
    let playerStats = playerNames.map((name) => {
      let stats = game.player_stats[name];
      stats['name'] = name;
      return stats;
    })
    let statsExist = false;
    playerStats.forEach((s) => {
      if (s.points || s.rebounds || s.assists) {
        statsExist = true;
      }
    })
    return statsExist;
  }

  function redirectToEditGame () {
    window.location.href = `/admin/games/${gameID}`
  }

  function showEditOption () {
    if (!currentUser) {
      return
    }

    if (!utils.isAdminEmail(currentUser.email)) {
      return
    }

    return (
      <span><a onClick={ redirectToEditGame } type="button">✏️</a></span>
    )
  }

  function displayReply (reply, replyDepth) {
    return (
      <>
        <div id={ `commentParent-${reply.id}` } key={reply.id}>
          <div id={ `comment-${reply.id}` } className={ `league-game-comment reply-${replyDepth} league-game-comment-reply` }>
            <div>
              <div className="league-commenter-image-mini commenter-image">
                <p className="commenter-image-initials">{ reply.author.firstName[0] }{ reply.author.lastName[0]}</p>
              </div>
            </div>
            <div className='league-game-comment-author-and-text'>
              <p className='league-comment-author-name'>{ reply.author.firstName } { reply.author.lastName }</p>
              <label className='league-comment-date'><span>{ getTimeAgo(reply.timestamp) }</span></label>
              <p className='league-comment-text'>{ reply.text }</p>

              { currentUser && currentPlayer ? <p id={`replyButtonElement-${reply.id}`} className="league-comment-reply" onClick={() => {addReply(reply.id)} }>Reply</p> : <></> }
              <div id={`replySpinner-${reply.id}`} className="spinner-border league-spinner-primary hidden" role="status"></div>
            </div>
          </div>
          <div id={ `replyGroup-${reply.id}` } className='hidden'>
            <div className="login-body">
              <div id={ `replyField-${reply.id}` } className="login-field">
                <label>Add a reply</label>
                <div className="form-group">
                  <div id={ `commentInputGroup-${reply.id}` } className="login-input-group">
                    <input type="text" id={ `commentInput-${reply.id}` }></input>
                  </div>
                </div>
                <label id={ `replyInputFieldError-${reply.id}` }></label>
              </div>
              <div className='comment-btn-grp'>
                <button id={ `postReplyButton-${reply.id}` } className="login-btn" type="submit" onClick={() => {postReply(reply.id)}}>Post</button>
                <button id={ `cancelReplyButton-${reply.id}` } className="login-btn-secondary" type="submit" onClick={() => {cancelReply(reply.id)}}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
        { displayReplies(reply.id, comments.replies.filter((r) => r.replyTo == reply.id), replyDepth) }
      </>
    )
  }

  function displayReplies (replyTo, replies, replyDepth) {
    replyDepth += 1

    return (
      <div id={ `allReplies-${replyTo}` } className='reply-list'>
        { replies.map((reply) => displayReply(reply, replyDepth)) }
      </div>
    )
  }

  function displayComments () {
    if (comments.posts.length == 0) {
      return (
        <>
          <p id="noCommentsText">No comments</p>
        </>
      )
    } else {
      return comments.posts.map((comment) => (
        <>
          <hr></hr>
          <div id={ `commentParent-${comment.id}` } key={comment.id}>
            <div id={ `comment-${comment.id}` } className="league-game-comment reply-0">
              <div>
                <div className="league-commenter-image-mini commenter-image">
                  <p className="commenter-image-initials">{ comment.author.firstName[0] }{ comment.author.lastName[0]}</p>
                </div>
              </div>
              <div className='league-game-comment-author-and-text'>
                <p className='league-comment-author-name'>{ comment.author.firstName } { comment.author.lastName }</p>
                <label className='league-comment-date'><span>{ getTimeAgo(comment.timestamp) }</span></label>
                <p className='league-comment-text'>{ comment.text }</p>

                { currentUser && currentPlayer ? <p id={`replyButtonElement-${comment.id}`} className="league-comment-reply" onClick={() => {addReply(comment.id)} }>Reply</p> : <></> }

                <div id={`replySpinner-${comment.id}`} className="spinner-border league-spinner-primary hidden" role="status"></div>
              </div>
            </div>
            <div id={ `replyGroup-${comment.id}` } className='hidden'>
              <div className="login-body">
                <div id={ `replyField-${comment.id}` } className="login-field">
                  <label>Add a reply</label>
                  <div className="form-group">
                    <div id={ `commentInputGroup-${comment.id}` } className="login-input-group">
                      <input type="text" id={ `commentInput-${comment.id}` }></input>
                    </div>
                  </div>
                  <label id={ `replyInputFieldError-${comment.id}` }></label>
                </div>
                <div className='comment-btn-grp'>
                  <button id={ `postReplyButton-${comment.id}` } className="login-btn" type="submit" onClick={() => {postReply(comment.id)}}>Post</button>
                  <button id={ `cancelReplyButton-${comment.id}` } className="login-btn-secondary" type="submit" onClick={() => {cancelReply(comment.id)}}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
          { displayReplies(comment.id, comments.replies.filter((r) => r.replyTo == comment.id), 0) }
        </>
      ))
    }
  }

  function displayPastGame () {
    let game = getGameFromGameID();
    let away_team = getTeamForID(game.away_team_id);
    let home_team = getTeamForID(game.home_team_id);

    let losing_team = game.winner === game.away_team_id ? home_team : away_team

    let gameStats = league.gameStats.filter((stat) => stat.game_id == game.id)
    let playedStats = gameStats.filter((stat) => stat.status == 'played')
    let forfeitStats = gameStats.filter((stat) => stat.status == 'forfeit')

    let statsCollected = playedStats.length > 0
    let gameForfeited = forfeitStats.length > 0

    return (
      <>
        <div className='league-game-display'>
          <div className='text-left'>
            <p className={ game.winner === game.away_team_id ? "league-title-standard-small" : "league-title-standard-small league-game-loser" }><a className="unstyled-link" href={"/teams/" + away_team.id }><b>{ away_team.name.toUpperCase() }</b></a></p>
          </div>

          <div className='text-right'>
            <p className={ game.winner === game.home_team_id ? "league-title-standard-small" : "league-title-standard-small league-game-loser" }><a className="unstyled-link" href={"/teams/" + home_team.id }><b>{ home_team.name.toUpperCase() }</b></a></p>
          </div>
        </div>

        <div className='league-game-display'>
          <div className='text-left'>
            <p className={ game.winner === game.away_team_id ? "league-title-standard no-margin" : "league-title-standard no-margin league-game-loser" }><b>{ game.score.away }</b></p>
          </div>

          <div className='text-center'>
            <p className='league-game-final-label no-margin'><b>
              <span className={ game.winner == game.home_team_id ? 'text-color-white' : '' }>← </span>
              Final
              <span className={ game.winner == game.away_team_id ? 'text-color-white' : '' }> →</span>
            </b></p>
            <p className='league-title-standard-small'>{ game.date.getMonth() + 1 }/{ game.date.getDate() }/{ game.date.getFullYear() }</p>
          </div>

          <div className='text-right'>
            <p className={ game.winner === game.home_team_id ? "league-title-standard no-margin" : "league-title-standard no-margin league-game-loser" }><b>{ game.score.home }</b></p>
          </div>
        </div>
        {
          statsCollected ?
          <>
            <div className="league-game-stats-container">
              <p className="league-title-skinny-medium"><b>Game Leaders</b></p>
              { showTopStats(gameStats, 'points') }
              { showTopStats(gameStats, 'rebounds') }
              { showTopStats(gameStats, 'assists') }
              { showTopStats(gameStats, 'blocks') }
              { showTopStats(gameStats, 'steals') }
            </div>

            <div id="boxScore" className="league-game-stats-container">
              <p className="league-title-skinny-medium"><b>Box Score</b></p>
              <div className="league-title-examples">
                <p className="league-title-example career-high-stat-example"><em>Player career high</em></p>
                <p className="league-title-example season-high-stat-example"><em>Season high</em></p>
                <p className="league-title-example league-high-stat-example"><em>League record</em></p>
              </div>

              <div className="league-table-container scroll-on-overflow">
                <table className="table league-table">
                  <thead className="league-table-heading-hole">
                    <tr>
                      <th className="league-table-heading-name" scope="col"><span>Player</span></th>
                      <th className="league-table-heading hole medium-col" scope="col"><span>PTS</span></th>
                      <th className="league-table-heading hole medium-col" scope="col"><span>REB</span></th>
                      <th className="league-table-heading hole medium-col" scope="col"><span>AST</span></th>
                      { SHOW_BLOCKS_AND_STEALS ? <>
                        <th className="league-table-heading hole medium-col" scope="col"><span>BLK</span></th>
                        <th className="league-table-heading hole medium-col" scope="col"><span>STL</span></th>
                      </> : '' }
                    </tr>
                  </thead>
                  <thead className="league-table-heading-total">
                    <tr>
                      <th className="league-table-heading-name" scope="col"><span>Player</span></th>
                      <th className="league-table-heading hole small-col" scope="col"><span>pts</span></th>
                      <th className="league-table-heading hole small-col" scope="col"><span>reb</span></th>
                      <th className="league-table-heading hole small-col" scope="col"><span>ast</span></th>
                      { SHOW_BLOCKS_AND_STEALS ? <>
                        <th className="league-table-heading hole small-col" scope="col"><span>blk</span></th>
                        <th className="league-table-heading hole small-col" scope="col"><span>stl</span></th>
                      </> : '' }
                    </tr>
                  </thead>
                  <tbody>
                    { displayPlayerInputRows(game, away_team, home_team) }
                  </tbody>
                </table>
              </div>
            </div>
            <div id="commentSection" className="league-game-stats-container">
              <p className="league-title-skinny-medium"><b>Comments</b></p>

              <div id="commentReplyGroup">
                <div className="login-body">
                  <div id="commentField" className="login-field hidden">
                    <label>Add a comment</label>
                    <div className="form-group">
                      <div id='commentInputGroup' className="login-input-group">
                        <input type="text" id='commentInput'></input>
                      </div>
                    </div>
                    <label id="commentInputFieldError"></label>
                  </div>
                  <div className='comment-btn-grp'>
                    { currentUser && currentPlayer ?
                      <button id="addCommentButton" className="login-btn" type="submit" onClick={addComment}>Add comment</button>
                      : currentUser ?
                      <p>Only players are allowed to comment at this time. If you are a player, make sure to use the email you signed up with.</p>
                      :
                      <button id="signInButton" className="login-btn" type="submit" onClick={signIn}>Sign in to comment</button>
                    }

                    <button id="postCommentButton" className="login-btn hidden" type="submit" onClick={postComment}>Post</button>
                    <button id="cancelCommentButton" className="login-btn-secondary hidden" type="submit" onClick={cancelComment}>Cancel</button>
                  </div>
                  <div id='commentSpinner' className="spinner-border league-spinner-primary hidden" role="status"></div>
                </div>
              </div>

              <div id="allCommentsGroup">
                { displayComments() }
              </div>
            </div>
          </> : gameForfeited ?
          <>
            <div className="league-game-stats-container">
              <p className="league-title-skinny-small">Game Forfeited</p>
              <p>This game was forfeited by { losing_team.name }.</p>
            </div>
          </> :
          <>
            <div className="league-game-stats-container">
              <p className="league-title-skinny-small">No Stats</p>
              <p>There were either no stats collected for this game or they are pending scorebook entry.</p>
            </div>
          </>
        }
      </>
    )
  }

  function getTimeAgo(timestamp) {
    const now = Date.now();
    const date = timestamp.toDate();
    const secondsAgo = Math.floor((now - date.getTime()) / 1000);

    if (secondsAgo < 604800) { // 604800 seconds = 1 week
      if (secondsAgo < 2) return `Just now`;
      if (secondsAgo < 60) return `${secondsAgo}s ago`;
      if (secondsAgo < 3600) return `${ Math.floor(secondsAgo / 60)}m ago`;
      if (secondsAgo < 86400) return `${ Math.floor(secondsAgo / 3600)}h ago`;
      return `${Math.floor(secondsAgo / 86400)}d ago`
    }

    // If it's more than a week ago, show full date
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return `${ date.toLocaleDateString(undefined, options) }`;
  }

  function signIn () {
    window.location.href = `https://www.leaguemorningstar.com/login?redirect=games/${gameID}`
  }

  function getStatsForGame (game_id) {
    return league.gameStats.filter((gameStat) => gameStat.game_id == game_id)
  }
  function getGameForID (game_id){
    return league.games.find((game) => game.id == game_id);
  }

  // to remove...
  function getStatsForPlayer (player_id, season, withStealsAndBlocks) {
    let allStatsForPlayer;
    if (withStealsAndBlocks) {
      allStatsForPlayer = league.gameStats.filter((gameStat) => gameStat.player_id == player_id && getGameForID(gameStat.game_id).date > DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED && gameStat.status == 'played')
    } else {
      allStatsForPlayer = league.gameStats.filter((gameStat) => gameStat.player_id == player_id && gameStat.status == 'played')
    }

    if (season == 0) {
      return allStatsForPlayer;
    } else {
      return allStatsForPlayer.filter((stats) => getGameForID(stats.game_id).season == season)
    }
  }
  function displayPlayerName (player) {
    return `${player.first_name} ${player.last_name}`
  }
  function getTeamForID(team_id) {
    return league.teams.filter((team) => team.id === team_id)[0];
  }

  function getPlayerByID(id) {
    return league.players.find((p) => p.player_id == id);
  }

  function showTopSeasonStats (game, away_team, home_team, stat, season = 0) {
    let playerIDsInGame = [...away_team.players, ...home_team.players]
    let playersInGame = playerIDsInGame.map((id) => getPlayerByID(id))

    // let seasonStats = {};
    let playerAverages = []

    playersInGame.forEach((p) => {
      let allStatsForPlayer;
      if (stat == 'blocks' || stat == 'steals') {
        allStatsForPlayer = getStatsForPlayer(p.player_id, season, true);
      } else {
        allStatsForPlayer = getStatsForPlayer(p.player_id, season, false);
      }

      if (allStatsForPlayer.length == 0) {
        return;
      }

      let average = allStatsForPlayer.reduce(function (avg, player, _, { length }) {
        return avg + player[stat] / length;
      }, 0);

      let playerTeam = utils.getCurrentTeamForPlayerID(league.teams, p.player_id)

      let playerAverage = {
        'player_id': p.player_id,
        'name': displayPlayerName(p),
        'team': playerTeam.name,
        'average': average
      };
      if (!isNaN(average)) {
        playerAverages.push(playerAverage)
      }
      // seasonStats[p.id] = allStatsForPlayer;
    })

    let topThreePlayerAverages = playerAverages.sort((function(a,b){
      return a.average > b.average ? -1 : 1;
    })).slice(0, 3);

    let topFivePlayerAverages = playerAverages.sort((function(a,b){
      return a.average > b.average ? -1 : 1;
    })).slice(0, 5);

    let statLabel = {
      'assists': 'Assists',
      'points': 'Points',
      'rebounds': 'Rebounds',
      'blocks': 'Blocks',
      'steals': 'Steals'
    }

    // Choose between top 3 or top 5 players to show
    let topPlayerAverages = topThreePlayerAverages;
    // let topPlayerAverages = topFivePlayerAverages;

    if (topPlayerAverages.length == 0) {
      return;
    } else {
      return (
        <div className='league-player-display'>
          <p className="league-title-standard-small text-center"><b>{ statLabel[stat] }</b></p>
          <div className='league-player-container'>
            <div className='league-game-player-stats'>
              { topPlayerAverages.map((player) => showAverageStatRow(player, stat) ) }
            </div>
          </div>
        </div>
      )
    }
  }

  function showTopStats (gameStats, stat) {
    let statLabel = {
      'assists': 'Assists',
      'points': 'Points',
      'rebounds': 'Rebounds',
      'blocks': 'Blocks',
      'steals': 'Steals'
    }

    // Remove the players if they did not record the stat
    gameStats = gameStats.filter((gameStat) => gameStat[stat])

    let topThreePlayerStats = gameStats.sort((function(a,b){
      let a_stat = a[stat];
      if (a_stat == '') {
        a_stat = -1
      }

      let b_stat = b[stat];
      if (b_stat == '') {
        b_stat = -1
      }
      return a_stat > b_stat ? -1 : 1;
    })).slice(0, 3);

    let topFivePlayerStats = gameStats.sort((function(a,b){
      let a_stat = a[stat];
      if (a_stat == '') {
        a_stat = -1
      }

      let b_stat = b[stat];
      if (b_stat == '') {
        b_stat = -1
      }
      return a_stat > b_stat ? -1 : 1;
    })).slice(0, 5);

    // Choose between top 3 or top 5 players to show
    let topPlayerStats = topThreePlayerStats;
    // let topPlayerStats = topFivePlayerStats;

    if (topPlayerStats.length == 0) {
      return;
    } else {
      return (
        <div className='league-player-display'>
          <p className="league-title-standard-small text-center"><b>{ statLabel[stat] }</b></p>
          <div className='league-player-container'>
            <div className='league-game-player-stats'>
              { topPlayerStats.map((playerStat) => showStatRow(playerStat, stat) ) }
            </div>
          </div>
        </div>
      )
    }
  }

  function showAverageStatRow(player, stat) {
    let statLabelAbbv = {
      'assists': 'APG',
      'points': 'PPG',
      'rebounds': 'RPG',
      'blocks': 'BPG',
      'steals': 'SPG'
    }
    let p = utils.getPlayerByID(league.players, player.player_id)
    let playerTeam = utils.getCurrentTeamForPlayerID(league.teams, p.player_id)
    return (
      <div className='league-game-player-stat-group'>
        <p className='league-game-player-name' key={player.player_id + '-player-name'}><a className="unstyled-link" href={"/players/" + player.player_id }>{ displayPlayerName(p) }</a> <span className='league-season-average-label'>({ playerTeam.name })</span></p>
        <p className='league-game-player-stat' key={player.player_id + '-player-stat'}>{ parseFloat(player.average.toFixed(1)) } <span className='league-season-average-label'>{ statLabelAbbv[stat] }</span></p>
      </div>
    )
  }

  function showStatRow(playerStat, stat) {
    let player = utils.getPlayerByID(league.players, playerStat.player_id)
    let playerTeam = utils.getCurrentTeamForPlayerID(league.teams, playerStat.player_id)

    if (!player) {
      let player_id = playerStat.player_id
      let team_id = playerStat.team_id
      player = defaultPlayer;
      player['player_id'] = player_id
      player['first_name'] = player_id
      player['last_name'] = ''
      player['team_id'] = team_id
    }

    return (
      <div className='league-game-player-stat-group'>
        <p className='league-game-player-name' key={player.player_id + '-player-name'}><a className="unstyled-link" href={"/players/" + player.player_id }>{ displayPlayerName(player) }</a> <span className='league-season-average-label'>({ getTeamForID(playerStat.team_id).name }{ playerTeam.id != playerStat.team_id && getGameForID(playerStat.game_id).season == SEASON ? '*' : '' })</span></p>
        <p className='league-game-player-stat' key={player.player_id + '-player-stat'}>{ playerStat[stat] }</p>
      </div>
    )
  }

  function playoffsStarted () {
    let unfinishedRegularSeasonGames = league.games.filter((game) => game.status !== 'completed' && game.type == 'regular_season')

    return unfinishedRegularSeasonGames == 0;
  }

  if (dataReady == false) {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Game">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">

          <div className="league-body">
            <div className="league-mid-width-container">
              <div className="league-game-display">
                <div className="text-left">
                  { playoffsStarted() ? <p className='league-game-back-button'><a className='league-link' href="/"><b>← Back to Bracket</b></a></p> : '' }
                  <p className='league-game-back-button'><a className='league-link' href="/schedule"><b>← View All Games</b></a></p>
                  <div className="dropdown league-dropdown">
                  </div>
                </div>
                <div className="text-right">
                  { showEditOption() }
                </div>
              </div>
            </div>

            <div className="schedule">
              { displayGame() }
            </div>
          </div>

        </div>

        <Footer/>
      </div>
    );
  }
}

export default Game;
