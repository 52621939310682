import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase.js';

let DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED = new Date('May 27, 2024');
let SAVE_BLOCKS_AND_STEALS = true;

// League Constants
export function playoffsStarted (league) {
  if (league.games.length == 0) {
    return false;
  }

  const unfinishedRegularSeasonGames = league.games.filter((game) => game.status !== 'completed' && game.type == 'regular_season')

  return unfinishedRegularSeasonGames == 0;
}
export function getCurrentPlayoffRound () {
  return 'championship';
}
export function showSeasonLeaders (league) {
  if (league.games.length == 0) {
    return false;
  }

  const seasonTeams = league.teams
  const finishedGames = league.games.filter((game) => game.status == 'completed')
  return finishedGames.length / seasonTeams.length > 2;
}

export function getCurrentOrMostRecentSeason () {
  return '3'
}
export function getUpcomingSeason () {
  return (parseInt(getCurrentOrMostRecentSeason()) + 1).toString()
}
export function getCurrentOrMostRecentSeasonStartDate () {
  // Season 3
  return new Date('October 15, 2024');
  // Season 2
  // return new Date('May 13, 2024');
}
export function getUpcomingSeasonStartDate () {
  return new Date('October 15, 2024');
}

export function showSignUps () {
  return false;
}

export function showPlayoffBracket () {
  return false;
}

export function getLocationName (location, abbv=false) {
  if (location == 'sbca') {
    if (abbv) {
      return 'SBCA'
    } else {
      return "South Boston Catholic Academy"
    }
  } else if (location == 'b&gc') {
    if (abbv) {
      return "B&GC"
    } else {
      return "Boys & Girls Club"
    }
  } else if (location == 'jpt-school') {
    if (abbv) {
      return 'TYNAN'
    } else {
      return 'Joseph P. Tynan School'
    }
  }  else {
    if (abbv) {
      return 'SBCA'
    } else {
      return "South Boston Catholic Academy"
    }
  }
}

export function displayTeamConference (team) {
  if (team.conference == 'west') {
    return 'Western Conference'
  } else if (team.conference == 'east') {
    return 'Eastern Conference'
  }
}

// Data Access - General
export function getTeamForID(teams, id) {
  return teams.find((team) => team.id == id);
}
export function getCurrentTeamForPlayerID (teams, player_id) {
  return teams.find((team) => team.players.includes(player_id))
}
export function getGameForID (games, id) {
  return games.find((game) => game.id == id);
}
export function getPlayerByID(players, id) {
  return players.find((player) => player.player_id == id);
}

// Data Access - Games
function getCompletedGames (league, season) {
  return league.games.filter((game) => game.status == 'completed' && game.season == season && game.type == 'regular_season')
}

// Database, Admin, etc.
export function getDatabaseSource () {
  const season = getCurrentOrMostRecentSeason();

  const production = `league_morning_star_2`;
  const test = `league_morning_star_2_test`;

  return production;
}

export async function syncTestDatabaseWithProduction (league) {
  const season = getCurrentOrMostRecentSeason();

  const production = `league_morning_star_2`;
  const test = `league_morning_star_2_test`;
  await setDoc(doc(db, "league", test), league);
}

async function syncProdDatabaseWithTest (league) {
  // await setDoc(doc(db, "league", production), league);
}


// Interpolated Team Data
export function getRecordForID (games, team_id) {
  let season = getCurrentOrMostRecentSeason()
  if (team_id) {
    let allGames = games.filter((game) => game.status === 'completed' && game.season == season  && (game.away_team_id === team_id || game.home_team_id === team_id))
    let allGamesWon = games.filter((game) => game.status === 'completed' && game.season == season && (game.winner === team_id))
    let gamesPlayed = allGames.length;
    let gamesWon = allGamesWon.length;
    return `${gamesWon}-${gamesPlayed - gamesWon}`
  } else {
    return '';
  }
}

// Interpolated Player Data
export function displayPlayerName (player) {
  if (player.first_name == null && player.last_name == null) {
    return `${player.first_name} ${player.last_name.replace("’", "'")}`
  } else {
    return `${player.firstName} ${player.lastName.replace("’", "'")}`
  }
}
export function displayPlayerHeight (player) {
  const height = player.height
  return `${height.feet}' ${height.inches}"`
}
export function displayJerseyNumber (player) {
  if (player.jersey_number == '') {
    return ``
  }
  return `#${player.jersey_number}`
}

// Miscallaneous
export function abbvWeekday (idx) {
  let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  return weekday[idx];
}

export function isAdminEmail (email) {
  let adminEmails = [
    'ccrane2@alumni.nd.edu',
    'chandlercharlescrane@gmail.com',
    'matthew.chignoli@gmail.com'
  ]

  return adminEmails.includes(email)
}

// Homepage helpers
export function getSeasonTeams(league, season) {
  const rankedTeams = rankTeams(league, season);
  const teams = [];
  rankedTeams.forEach((team, place) => {
    teams.push({
      'id': team.id,
      'name': team.name,
      'nameAbbv': team.name_abbv,
      'place': place + 1,
      'conference': team.conference,
      'wins': team.stats.wins,
      'losses': team.stats.losses,
      'winPercentage': (team.stats.wins / (team.stats.wins + team.stats.losses)).toFixed(3).toString().slice(1),
      'pointsFor': team.stats.pointsFor,
      'pointsAgainst': team.stats.pointsAgainst,
      'streak': displayStreak(team.stats.streak)
    })
  });

  return teams;
}

function displayStreak (streak) {
  if (streak === 0) {
    return '-';
  }
  else if (streak < 0) {
    return Math.abs(streak).toString() + 'L'
  } else {
    return streak.toString() + 'W'
  }
}

function getStatsForTeams (league, season) {
  let teamData = {};
  league.teams.forEach((team) => teamData[team.id] = {
    wins: 0,
    losses: 0,
    games: 0,
    streak: 0,
    pointsAgainst: 0,
    pointsFor: 0
  })

  let completedGames = getCompletedGames(league, season);

  let sortedCompletedGames = completedGames.sort((a, b) => (a.date < b.date) ? -1 : 1)
  sortedCompletedGames.forEach((game) => {
    let winner = game.winner;
    let loser = game.winner == game.away_team_id ? game.home_team_id : game.away_team_id;

    let winnerScore = game.score.away > game.score.home ? game.score.away : game.score.home;
    let loserScore = game.score.away > game.score.home ? game.score.home : game.score.away;

    if (teamData[winner]) {
      teamData[winner].wins += 1;
      teamData[winner].games += 1;
      teamData[winner].pointsAgainst += loserScore;
      teamData[winner].pointsFor += winnerScore;
      if (teamData[winner].streak < 0) {
        teamData[winner].streak = 1;
      } else {
        teamData[winner].streak += 1;
      }
    }

    if (teamData[loser]) {
      teamData[loser].losses += 1;
      teamData[loser].games += 1;
      teamData[loser].pointsAgainst += winnerScore;
      teamData[loser].pointsFor += loserScore;
      if (teamData[loser].streak > 0) {
        teamData[loser].streak = -1;
      } else {
        teamData[loser].streak -= 1;
      }
    }
  })

  let defaultStats =  {
    pointsFor: '0',
    pointsAgainst: '0',
    streak: '-',
    wins: '0',
    losses: '0',
    games: '0',
    winPercentage: '.000',
    winPercentageValue: 0
  };

  let newTeamList = [];
  league.teams.forEach((team) => {
    let t = teamData[team.id];
    if (t.wins === t.games && t.games > 0) {
      t.winPercentage = '1.000';
      t.winPercentageValue = 1;
    } else if(t.wins + t.losses !== 0){
      t.winPercentage = (t.wins / t.games).toFixed(3).toString().slice(1);
      t.winPercentageValue = t.wins / t.games
    }

    let existingTeamData = team;
    let stats = {...defaultStats, ...teamData[team.id]};
    existingTeamData.stats = stats;

    newTeamList.push(existingTeamData);
  })

  return newTeamList;
}

function rankTeams (league, season) {
  let teams = getStatsForTeams(league, season);

  // Ranking by Win Percentage

  let teams_sorted = teams.sort((a, b) => {
    if (a.stats.winPercentageValue > b.stats.winPercentageValue) { // .321 team > .123 team
      return -1;
    } else if (a.stats.winPercentageValue < b.stats.winPercentageValue) { // .123 team < .321 team
      return 1;
    } else if (a.stats.losses > b.stats.losses) { // 0-1 teams > 0-2 teams
      return 1;
    } else if (a.stats.losses < b.stats.losses) { // 0-2 teams < 0-1 teams
      return -1;
    } else if (a.stats.games > b.stats.games) { // 2-0 teams > 1-0 teams
      return -1;
    } else if (a.stats.games < b.stats.games) { // 1-0 teams < 2-0 teams
      return 1;
    } else {
      // Tiebreaker #1 - Head to Head
      let headToHead = headToHeadValue(league, season, a,b);
      if (headToHead !== 0) {
        return headToHead;
      } else {
        // Tiebreaker #2 - Point Differential +/-
        let pointDifferentialTeamA = a.stats.pointsFor - a.stats.pointsAgainst;
        let pointDifferentialTeamB = b.stats.pointsFor - b.stats.pointsAgainst;
        if (pointDifferentialTeamA > pointDifferentialTeamB) {
          return -1;
        } else if (pointDifferentialTeamA > pointDifferentialTeamB) {
          return 1;
        } else {
          return 0;
        }
      }
    }
  })

  // update this seed data when submitting scores in admin view
  // for(let i = 0; i < teams_sorted.length; i++) {
  //   teams_sorted[i]['seed'] = i+1;
  // }
  // console.log(teams_sorted)
  // league.teams = teams_sorted
  // await setDoc(doc(db, "league", 'league_data_test'), league);

  return teams_sorted;
}

function headToHeadValue (league, season, a,b) {
  let gamesAgainstEachother = league.games.filter((game) => game.status == 'completed' && game.season == season && game.type == 'regular_season' && ((game.away_team_id == a.id && game.home_team_id == b.id) || (game.away_team_id == b.id && game.home_team_id == a.id)))
  let gamesTeamAWon = gamesAgainstEachother.filter((game) => game.winner == a.id)

  if (gamesAgainstEachother.length == 0) {
    return 0;
  } else if (gamesTeamAWon.length / gamesAgainstEachother.length < .5) {
    return 1;
  } else if (gamesTeamAWon.length / gamesAgainstEachother.length > .5) {
    return -1;
  } else {
    return 0;
  }
}

export function getSeasonLeaders(league, season, leaderCount) {
  const currentPlayers = getCurrentPlayers(league)
  const stats = ['points', 'rebounds', 'assists', 'blocks', 'steals'];

  let leaders = []

  for (const stat of stats) {
    let playerAverages = []
    currentPlayers.forEach((p) => {
      let allStatsForPlayer;
      let withStealsAndBlocks = true;
      if (stat !== 'blocks' && stat !== 'steals') {
        withStealsAndBlocks = false;
      }
      allStatsForPlayer = getStatsForPlayer(league, p.player_id, season, withStealsAndBlocks);


      if (allStatsForPlayer.length == 0) {
        return;
      }

      let average = allStatsForPlayer.reduce(function (avg, player, _, { length }) {
        // Don't show players with less than half games played (on average)
        // TODO: Dynamically program this so I don't need to edit this throughout the season
        let completedGames = getCompletedGames(league, season);

        if (length < 5 && completedGames.length > 40) {
          return 0;
        }

        return avg + player[stat] / length;
      }, 0);

      let playerAverage = {
        'player_id': p.player_id,
        'name': displayPlayerName(p),
        'average': average
      };
      if (!isNaN(average)) {
        playerAverages.push(playerAverage)
      }
    })

    let topPlayerAverages = playerAverages.sort((function(a,b){
      return a.average > b.average ? -1 : 1;
    })).slice(0, leaderCount);

    for (const player of topPlayerAverages) {
      let team = getCurrentTeamForPlayerID(league.teams, player.player_id)

      leaders.push(
        {
          'id': player.player_id,
          'stat': stat,
          'name': player.name,
          'teamName': team.name,
          'value': player.average
        }
      )
    }
  }

  return leaders;
}

function getStatsForPlayer (league, player_id, season, withStealsAndBlocks) {
  let allStatsForPlayer;
  if (withStealsAndBlocks) {
    allStatsForPlayer = league.gameStats.filter((gameStat) => gameStat.player_id == player_id && getGameForID(league.games, gameStat.game_id).date > DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED && gameStat.status == 'played')
  } else {
    allStatsForPlayer = league.gameStats.filter((gameStat) => gameStat.player_id == player_id && gameStat.status == 'played')
  }

  if (season == 0) {
    return allStatsForPlayer;
  } else {
    return allStatsForPlayer.filter((stats) => getGameForID(league.games, stats.game_id).season == season)
  }
}

export function getCurrentPlayers (league) {
  let currentPlayers = league.teams.map((team) => team.players.map((id) => getPlayerByID(league.players, id))).flat()
  // Known limitation - there are a few players who have stats but are not in the players table
  // They cannot show up in the top season stats. This if fine because they don't anyways.
  // In the future, we may want to allow this or just create player entries for them.
  return currentPlayers.filter((p) => p !== undefined)
}
